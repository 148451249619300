export const blogs = [
    {date:"2008-12-18 13:12:48",name:"My First Ghazal",body:`<p style="text-align: center;font-size:16px">सबकी सांसे सबका जीवन , मेरा जीवन तुम ही साजन |</p>
<p style="text-align: center;font-size:16px">मेरा क्या है , क्या मैं बताऊ , मेरा जीवन तेरा साजन |</p>
<p style="text-align: center;font-size:16px">भंवरा जैसे फूल बिना है , मेरा जीवन तुम बिन साजन |</p>
<p style="text-align: center;font-size:16px">देर लगी क्यो तुमको इतनी ,तेरी तलाश में भटका जीवन |</p>
<p style="text-align: center;font-size:16px">बहुत तलाशा मैंने इसको , तुझमे मिला है मेरा जीवन |</p>
<p style="text-align: right;">H.P.RAHI</p>`},
{date:"2008-12-18 14:27:02",name:"One of My Favourite ( Ghazal )",body:`<p style="font-size: 16px; text-align: center;">शुक्रिया के हमारे अंजुमन में आप आए है ,</p>
<p style="text-align: center;font-size: 16px;">सब ओर बरस रहे है जलवे , के सरकार आए है |</p>
<p style="text-align: center;font-size: 16px;"></p>
<p style="text-align: center;font-size: 16px;"></p>
<p style="font-size: 16px; text-align: center;">तुम्ही से रोशन हर महफिल , तुम्ही से रोशन हर लम्हा ,</p>
<p style="text-align: center;font-size: 16px;">के आज की शब रौशनी में नहाने यहाँ महताब आए है |</p>
<p style="text-align: center;font-size: 16px;"></p>
<p style="text-align: center;font-size: 16px;"></p>
<p style="font-size: 16px; text-align: center;">सरफरोशी कि तमन्ना थी हमारे दिल में भी ,</p>
<p style="text-align: center;font-size: 16px;">मगर अब करेंगे गुलामी आपकी , के आप शम्मे बहार लाये है |</p>
<p style="text-align: center;font-size: 16px;"></p>
<p style="text-align: center;font-size: 16px;"></p>
<p style="font-size: 16px; text-align: center;">दास्ताने सिफर सुनाते थे जो पैमाने कभी ,</p>
<p style="text-align: center;font-size: 16px;">वोह आज ख़ुद नशे में डूबने कि गुहार लाये है |</p>
<p style="text-align: center;font-size: 16px;"></p>
<p style="text-align: center;">अंजुमन - सभा , शब् - रात , महताब - चाँद , सिफर - शुन्य |</p>
<p style="text-align: right;">H.P.RAHI</p>`},
{date:"2008-12-23 16:42:48",name:"Deewanagi (Song)",body:`<p style="text-align: center;font-size:16px">उनकी एक अदा पर हम क्या कर गए ,
दीवानगी की हद से हाय गुज़र गए |

<p style="text-align: center;font-size:16px">उनसे नज़रे मिले कब , दिल ये बातें सोचे अभी ,
इस आशिकी के रास्तो से, कैसे गुज़रे यह सोचे अभी ,
आँख भी ना लगे अब , कैसे कांटे यह राते सभी ,
नही होश में थे , कहा जा रहे थे ,
नही थी हमको ख़बर , क्यों गा रहे थे ,
के पागल हुए हम अभी , जो हदों से गुज़र गए |

<p style="text-align: center;font-size:16px">उनकी एक अदा पर हम क्या कर गए ,
दीवानगी की हद से हाय गुज़र गए |

<p style="text-align: center;font-size:16px">उनकी शोखियों से हम थे थोड़े अजनबी ,
वफाओ से टकरा गए हम , सोचे थे जो न कभी ,
क्या होती है मुहब्बत , यह हमने था न जाना कभी ,
यकीन पा रहे थे या शरमा रहे थे ,
सुकून मिल रहा था , जो दिल पा रहे थे ,
के बातें करते , मुस्कुराते , वादे कर गए |

<p style="text-align: center;font-size:16px">उनकी एक अदा पर हम क्या कर गए ,
दीवानगी की हद से हाय गुज़र गए |
<p style="text-align: right">H.P.RAHI</p>`},
{date:"2008-12-24 22:25:52",name:"tareef ( Ghazal )",body:`<p style="text-align: center;font-size:16px">तेरी आँखों ने यह जो कह दिया ,
बस इसको ही मैंने शायरी नाम रख दिया |

<p style="text-align: center;font-size:16px">तालियाँ बजी थी , तारीफे थी मेरी ,
तेरा नाम जो मैंने महफिल में कह दिया |

<p style="text-align: center;font-size:16px">जिस रात दिखा था तेरा चेहरा ,
उस रात को मैंने चौदहवी कह दिया |

<p style="text-align: center;font-size:16px">तुम भी बन जाओगे मशहूर शायर ,
मेरी तरह जो तुमने उनको सुन लिया |
<p style="text-align: right; ">H.P.RAHI</p>`},
{date:"2008-12-24 22:31:33",name:"Woh Khat ( Ghazal )",body:`<p style="text-align: center;font-size: 16px;">सुबह को तेरा ख़त जो मिला था ,
सहरा में इक फूल खिला था |

<p style="text-align: center;font-size: 16px;">तेरी आँखें याद आई थी ,
मयखाने को भूल गया था |

<p style="text-align: center;font-size: 16px;">अँधेरा ही हकीकत थी मेरी ,
कोई दीप ले आया वोह तेरा ख़त था |

<p style="text-align: center;font-size: 16px;">शाम को मैं फिर मयखाने में हूँ ,
जो मिला था, तेरा कोई पुराना ख़त था |
<p style="text-align: center;">सहरा - रेगिस्तान</p>
<p style="text-align: right;">H.P.RAHI</p>`},
{date:"2008-12-24 22:34:24",name:"Numaeesh ( Ghazal )",body:`<p style="text-align: center;font-size:16px">रेगजारो के दिल की गुलिस्ता क्या जाने ,
बहोत से ख्वाब नुमाइश पर लगा आया हूँ |

<p style="text-align: center;font-size:16px">दिल ता जिगर तेरी रहगुज़र का मुंतजीर सा हुआ ,
गाफिल वहा हर साँस भूल आया हूँ |

<p style="text-align: center;font-size:16px">हर रंग मेरा कैस का हर रंग लगे ,
अपनी सूरत के कई नकाब गिरा आया हूँ |

<p style="text-align: center;font-size:16px">मेरा परवर भी गमगुसार हुआ ,
तेरे फरेब पर एक गर्द बिछा आया हूँ |
<p style="text-align: center;font-size:16px"></p>

<p style="text-align: center;">रेगज़ार - रेगिस्तान , मुंतजीर - इंतज़ार करने वाला ,
गाफिल - बेहोश , कैस - मजनू , गर्द - धुल |
<p style="text-align: right;">H.P.RAHI</p>`},
{date:"2008-12-24 22:42:26",name:"andaza ( shayri )",body:`<p style="font-size:16px">बहोत देर तक अंदाजा लगाते रहे ,
वोह आयेंगे किस ओर से दिल में ,
ज़िन्दगी ऐसे ही ख्याल बुनती रही ,
वोह आए और आकर चले गए ,
हम बस बहोत देर तक अंदाजा ही लगाते रहे |      <span style="font-size:12px"> H.P.RAHI</span>`},
{date:"2009-01-08 14:42:41",name:"yu rato me na milne aao ( ghazal )",body:`<p style="text-align: center;font-size:16px">मेरे मुफलिस तेरे ही दामन में सोया हूँ ,
ख़बर भी नही इतनी गुम हो , क्या मेरे ख्यालो में खोयी हो |

<p style="text-align: center;font-size:16px">शब् भी बहोत लम्बी सी है ,
इसे तेरे आने की ख़बर है जो |

<p style="text-align: center;font-size:16px">तन मन में इतना शोर मचा है ,
आज सिने में जैसे दो दिल हो |

<p style="text-align: center;font-size:16px">तुमको रोकू पहरों पहरों ,
गर महताब पे बस चल जाए तो |

<p style="text-align: center;font-size:16px">इस जालिम दिल को रोक सकू ,
यु रातों में न मिलने आओ तो |

<p style="text-align: center;">मुफलिस - निर्धन , शब् - रात ,
महताब - चाँद |
<p style="text-align: right;">H.P.RAHI</p>`},
{date:"2009-01-08 15:03:19",name:"aap hum me woh yarana woh muravvat kaha ( Ghazal )",body:`<p style="text-align: center;font-size:16px">रेत के महल बनते है सिर्फ़ टूटने के लिए ,
इनके इरादों में वोह माहो-साल कहा |
दे दो जवाब सवाल मुस्तकविल मेरे ,
आपके जिगर में वो हाल कहा |

<p style="text-align: center;font-size:16px">हाले दिल भी कभी सुना होता ,
तड़प होती है क्या यह जाना होता |
करते थे तसल्ली किसी ज़माने में ,
दीद को भी अब वोह दीदार कहा |

<p style="text-align: center;font-size:16px">भड़क उठते ये शोले कभी न बुझने को ,
जो कभी बेडियों में शबनम को बंधा होता |
अपनी चाहतो को करते खुल कर बयां पर ,
आप हममे वोह याराना वोह मुरव्वत कहा | 

<p style="text-align: center;">दीद - दर्शन ,मुरव्वत - लिहाज |</p>
<p style="text-align: right;">H.P.RAHI</p>`},
{date:"2009-01-08 15:06:54",name:"tere karam ( Shayri )",body:`<p style="font-size:16px">हमारे गुनाहों की दास्ताँ लिख रहा हूँ मैं ,
कभी जीत तो कभी हार लिख रहा हूँ मैं ,
मेरे गुनाहों को माफ़ करने वाले ,
मुझ पर कर न सके जो तुम , वोह करम लिख रहा हूँ मैं |      <span style="font-size:12px"> H.P.RAHI</span>`},
{date:"2009-01-08 16:45:58",name:"intezaar ( Shayri )",body:`<p style="font-size:16px">हम रात जागते रह गए , हम राह तकते रह गए ,
शायद वोह मोड़ मुड गए होंगे , या हम ही किसी की मंजिल न थे | <span style="font-size:12px">H.P.RAHI</span>`},
{date:"2009-01-16 01:34:11",name:"chalka chalka paimana ( Shayri )",body:`<p style="font-size:16px">यह झूठो की बस्ती है , यह मुर्दों का तहखाना है ,
इनसे क्या लेना देना मुझे , अपना हमदम तो विराना है |
अब साजे सागर क्या कहिये , छलका छलका पैमाना है ,
जो बीत गए वोह दिन थे मेरे , है शाम जिसे अब जाना है | <span style="font-size:12px">H.P.RAHI</span>`},
{date:"2009-02-01 12:37:05",name:"woh kya raat thi ( Ghazal )",body:`<p style="text-align: center;font-size:16px">वो रात भी क्या रात थी ,
तुम थे और था चाँद थोड़ा थोड़ा |

<p style="text-align: center;font-size:16px">कर रहे थे प्यार मुझको ,
और था इताब थोड़ा थोड़ा |

<p style="text-align: center;font-size:16px">थोडी थोडी मुहब्बत थी और ,
था तेरा नखरा थोड़ा थोड़ा |

<p style="text-align: center;font-size:16px">सारी छत पे थी खुशबू महकी ,
जो था तेरा इकरार थोड़ा थोड़ा |

<p style="text-align: center;">इताब - गुस्सा |</p>
<p style="text-align: right;">H.P.RAHI</p>`},
{date:"2009-02-01 12:40:34",name:"jine ke bahane bahot hai ( Shayri )",body:`<p style="font-size:16px">दिल रोता है , होंठ मुस्कुराते है ,
मुस्कुराने के बहाने बहोत है |
मायूस हो जाता हु रोज मगर ,
जीने के बहाने बहोत है |<span style="font-size:12px">H.P.RAHI</span>`},
{date:"2009-02-01 12:45:04",name:"tum bin ( Ghazal )",body:`<p style="text-align: center;font-size:16px">कैसे रहोगे तन्हा हम बिन ,
जी न सकेंगे हम भी तुम बिन |

<p style="text-align: center;font-size:16px">दिल पर बोझ यादो का इतना ,
मर भी सकेंगे कैसे तुम बिन |

<p style="text-align: center;font-size:16px">शाम तो कट ही गई पीते पीते ,
जाने कैसे कटेगी यह रात तुम बिन |

<p style="text-align: center;font-size:16px">'राही' भूल रहा है अपनी मंझिल ,
बदले बदले से जो है यह नज़ारे तुम बिन | 

<p style="text-align: right;">H.P.RAHI</p>`},
{date:"2009-02-01 13:06:27",name:"diljala ( Ghazal )",body:`<p style="text-align: center;font-size:16px">वोह यार मेरा दिलदार मेरा कुछ अनसुना सा हो गया ,
जल गए हम भी कसक में , वोह दिलजला सा हो गया |

<p style="text-align: center;font-size:16px">जब समंदर से मैं गुजरा साया अपना खो गया ,
जिसकी तलाश में था मैं भटका , ये सहरा पासवा सा हो गया |

<p style="text-align: center;font-size:16px">उस किरण का था ये वादा , साथ में हमसाया होगा ,
बुझना ही बाकी था जिसका ,  जलजला सा हो गया |

<p style="text-align: center;font-size:16px">उन दिलजलो की याद में नुकसान अपना हो गया ,
जो था कभी दुश्मन सा वोह , अब मेहरबा सा हो गया | 

<p style="text-align: center;">सहरा - रेगिस्तान |</p>
<p style="text-align: right;">H.P.RAHI</p>`},
{date:"2009-02-25 21:34:37",name:"mera jakhm kuch aur khul gaya ( Ghazal )",body:`<p style="text-align: center;font-size:16px">मेरी तन्हाइयो का साथी मुझको मिल गया ,
तिशनगी थी इतनी कि सारा मयकदा मैं पि गया |

<p style="text-align: center;font-size:16px">मेरी पहचान का कोई काफिला गुजरा था मेरे दर से ,
शहनायिओ कि आवाज थी कि मैं सारी रात जी गया |

<p style="text-align: center;font-size:16px">था नहीं यकीन कि इस तरह से गुजरोगी तुम मेरे सामने से ,
उस यकीन कि मौत थी कि जशने ग़म का आलम वोह दे गया |

<p style="text-align: center;font-size:16px">वोह शब् कटी के मैं कटा कुछ भी नहीं खबर ,
था चुप्पियों का शोर के मेरा जख्म कुछ और खुल गया |

<p style="text-align: center;">तिशनगी - प्यास , शब् - रात |</p>
<p style="text-align: right;">H.P.RAHI</p>
`},
{date:"2009-02-25 21:37:14",name:"kaarwa ghamo ka ( Ghazal )",body:`<p style="text-align: center;font-size:16px">कारवा गमो का गुजरा था मेरे दर से ,
हमारे हाथो से सागर टूटने से लगे है |

<p style="text-align: center;font-size:16px">तुम्हारी दुआए हम तक पहुची ही थी ,
जिंदगी और मौत के सिरे छुने से लगे है |

<p style="text-align: center;font-size:16px">भीगने लगी है जिंदगी की राहें ,
दिलो के जख्म सुबकने से लगे है |

<p style="text-align: center;font-size:16px">अपने अंजुमन में क्या कमी थी आंसुओ की ,
वक़्त के कुछ और सितम जुड़ने से लगे है |

<p style="text-align: center;">सागर - शराब का प्याला , अंजुमन - सभा |</p>
<p style="text-align: right;">H.P.RAHI</p>`},
{date:"2009-03-31 21:09:31",name:"woh nadi kuch yu bahi ( Shayri )",body:`<p style="font-size:16px">रात की खामोशियों को सुनना हमको आ गया ,
वोह नदी कुछ यु बही कि सागर ही मिलने आ गया | <span style="font-size:12px">H.P.RAHI</span>`},
{date:"2009-03-31 21:20:45",name:"ummeede mitati chali gayi ( Shayri )",body:` wrote after getting harassed with kota exeperience.

<p style="font-size:16px">उम्मीदे मिटती चली गयी , रास्ते जलते चले गए ,
जिंदगी का सफ़र चल न पाए हम , हर दिन हर रात मरते चले गए |
उफनती नदिया सागर सी लगने लगी , सुखी हुयी नहरे सहरा सी लगने लगी ,
बिगडे आसान काम भी , हम खुद को ठगते चले गए |<span style="font-size:12px">H.P.RAHI</span>
<p style="text-align: center;">सहरा - रेगिस्तान |</p>`},
{date:"2009-03-31 21:26:56",name:"nain, dil, lab ya kaish kahi koi to naraj hai ( Ghazal )",body:`<p style="text-align: center;font-size:16px">दबे दबे से गीत के पीछे  जाने क्या राज है ,
क्या तुम्हारा प्यार मेरे लिए अब बिना परवाज है |

<p style="text-align: center;font-size:16px">आज दर पर आहट हुयी , पर कुछ रौशनी न हुयी ,
दिल तक न पहूची यह तुम्हारी कौनसी आवाज है |

<p style="text-align: center;font-size:16px">जानते है यह संसार है नापाक तो होगा ही ,
पर हमारे बीच में यह कौन जालसाज़ है |

<p style="text-align: center;font-size:16px">तुम्हारे इनकार कि कुछ तो वजह होगी ,
नैन , दिल ,  लब या कैश , कही कोई तो नाराज़ है |
<p style="text-align: right;">H.P.RAHI</p>`},
{date:"2009-03-31 21:33:24",name:"jaroori to nahi ( Shayri )",body:`<p style="font-size:16px">देखना चाहे कोई उनको तो हर जगह देखे ,
पर हर नजारे की नज़र हो यह जरूरी तो नहीं |
उनकी आँखों के निशाने से ही मर जाए लेकिन ,
उस निशाने पर यह दिल हो यह जरूरी तो नहीं |<span style="font-size:12px">H.P.RAHI</span>`},
{date:"2009-04-11 17:03:48",name:"Ab woh ek Ahsaas hi hai bas ( Nazm )",body:`<p style="text-align: center;font-size:16px">सुन मेरे खुदा , आज मुझ पर एक करम कर ही दे,
मुझे वक़्त से कुछ पल के लिए आजाद कर दे,
कही किसी मोड़ पर कोई छुट गया है,
उसे जरा फिर एक झलक देख आऊ,
हो सके तो थोडी सी सांस ले आऊ ,
जिदगी का भरोसा मेने खूब देखा है ,
आज नहीं गया तो न जाने फिर कब वक़्त मिलेगा मुझे ,
आदत नहीं है , तनहा सफ़र होता नहीं है मुझसे ,
वोह कोई रहगुज़र जो मेरे साथ हमेशा थी ,
बस उस पर से कुछ कदमो के निशान ही ले आऊ ,
मैं जाकर वोह खुशबू ही कैद कर ले आऊ,
जो महका देती थी मेरे हर जर्रे को ,
जो अनुभव बस बन कर रह गया है एक अहसास ही |
<p style="text-align: right;">H.P.RAHI</p>`},
{date:"2009-04-13 10:48:17",name:"har waqt dhua sa rahta hai ( Ghazal )",body:`<p style="text-align: center;font-size:16px">हर तरफ हर वक़्त धुआं सा रहता है |
एक धुप का टुकडा डरा सा रहता है |

<p style="text-align: center;font-size:16px">जब भी गुज़रा मैं जली इन बस्तियों से ,
तजरूबा मेरा बुरा सा रहता है |

<p style="text-align: center;font-size:16px">आपके शहर से दोस्ती हो कैसे ,
हर शख्स कितने शख्स बना सा रहता है |

<p style="text-align: center;font-size:16px">जिन्दगी एक तपिश सी क्यों है ,
जो भी जीता है , जला सा रहता है |
<p style="text-align: right;">H.P.RAHI</p>`},
{date:"2009-04-14 00:01:23",name:"u, me aur hum (Shayri)",body:`गुजर रहे थे हम राहों से..तेरी खुशबु सी आई,
हिम्मत नहीं हुई की तुझे ढूंढ़ सकें..बस जरा आखें भर आई,
इस कदर बसे हो सासों में तुम..शायद ज़िन्दगी भी हमे जुदा न कर पायी

DT`},
{date:"2009-04-14 00:16:12",name:"meri tanhai (Shayri)",body:`एक गुजरती हुई शाम बन गयी हो तुम..
जो मदहोश कर दे वो जाम बन गयी हो तुम..
इस कदर तेरी यादों में खोये रहते हैं की..
मेरी तन्हाई का नाम बन गयी हो तुम!

DT`},
{date:"2009-04-14 00:27:01",name:"koshish.. (Shayri)",body:`न जाने कितने दिनों बाद लिखने का मन किया है..
न जाने इस बीच मैंने क्या जीया है..
शायद बीत गया एक अरसा एक लम्हा जैसे..
लाऊंगा वो लम्हे अब में वापिस कैसे..
कुछ दर्द मिला होगा उनमें जो वापिस यहाँ हूँ..
देखो मैं आज फिर कुछ लिखने लगा हूँ..
कुछ टूटा सा, कुछ फूटा सा..
एक साथी कहीं कोई छूटा सा..
कोशिश कर रहा हूँ में वो कहानी बताने की..
कोशिश मेरी फिर से है इक शेर बनाने की!

DT`},
{date:"2009-04-14 09:00:29",name:"chahat (Shayri)",body:`हर आहट में बस आहट तेरी है...
हर चेहरे में बस सूरत तेरी है..
लोग कहते हैं की तू नहीं है..
हमारी तो हर इबादत में बस चाहत तेरी है!!!

DT`},
{date:"2009-04-19 19:49:52",name:"toofan..",body:`एक दिन कुछ ऐसा आया मेरी ज़िन्दगी  में..
कोई यादें रह गया..
कोई पिंजर रह गया!!

DT`},
{date:"2009-04-24 16:41:14",name:"do chand hi bo diye to kya ( Ghazal )",body:`<p style="text-align: center;font-size:16px">एक 'राही' भुला भटका सा , दो चार कदम पर मंजिल क्या ,
यह बहोत आम सी जिंदगी , एक उम्र जिए तो जिए ही क्या |

<p style="text-align: center;font-size:16px">हर शाम थकी इन राहो को मैं घर की राह दिखाता हूँ ,
कुछ करने की यह जिद ही सही , दो ख्वाब ही देख लिए तो क्या |

<p style="text-align: center;font-size:16px">खुद अपनी जमीं पे अपना आसमान उगायेंगे ,
चाहे सितारे साथ नहीं , दो चाँद ही बो दिए तो क्या |

<p style="text-align: center;font-size:16px">अपने लिए सब एक बराबर जश्न का मौका होता है ,
वो रात का सन्नाटा कैसा , और दिन का पागलपन ही क्या |
<p style="text-align: right;">H.P.RAHI</p>`},
{date:"2009-04-28 10:15:35",name:"mausam",body:`कुछ इस तरह बरसा है मौसम..बस रात है इन आखों में
कुछ इस तरह तनहा है मौसम..बात सिर्फ तेरी मेरी बातों में
तू सुन रही है अगर मुझे..कोई इक इशारा तो दे
जीने का बहाना मिले..शायद तेरे इशारों में
सुना है..आते हैं मौसम चार बीतते इन सालों में
हमारा तो अब एक है मौसम ज़िन्दगी की राहों में

DT`},
{date:"2009-05-08 11:36:28",name:"hausla ( ghazal )",body:`<p style="font-size:16px">मेरा कम नहीं है हौसला , न यु अदा से मिला करो ,
मेरे घर में भी है आइना , न नज़र से इतनी दुआ करो |

<p style="font-size:16px">मुझे ग़म नहीं किसी और का , जैसी भी यह ज़ीस्त सही ,
मुझे कह दो बेवफा मगर , न इस तरह से वफ़ा करो |

<p style="font-size:16px">सौ बार के ग़म से बेहतर एक जान ही दे दे न क्यों ,
एक कोशिश तो पूरी होने दो , न मुझे यु साँसे दिया करो |
<p style="text-align: center;">जीस्त - जिंदगी  |</p>
<p style="text-align: right;">H.P.RAHI</p>`},
{date:"2009-05-10 12:48:38",name:"sazda ( Shayri )",body:`<p style="font-size:16px">सजदा मुनासिब हो अगर वादाखिलाफी हो जाये ,
बेकसी पर नज़र से भरी कुछ खता तो हो जाये |<span style="font-size:12px">H.P.RAHI</span>
<p style="text-align: center;">बेकसी - बेबसी |</p>`},
{date:"2009-05-19 07:45:18",name:"teri baahein ( Ghazal )",body:`<p style="text-align: center;font-size:16px">तेरी बाहे सुनी राहें छोड़ कर जा चुकी होंगी ,
कही तो आग लगी होगी , कही बरसात हुई होगी |

<p style="text-align: center;font-size:16px">रहा करते थे जिनकी धडकनों में हम ,
तडपता छोड़ कर जाना उनकी आदते होंगी |

<p style="text-align: center;font-size:16px">उन्हें हम ग़म के मारो का मसीहा कहते फिरते थे ,
के मसीहा बन के तरसाना उनकी ख्वाहिशे होंगी |

<p style="text-align: center;font-size:16px">लरबते होठो के प्याले छुआ करते थे होठो से ,
मगर दिल ही न छु पाए , यह अपनी किस्मते होंगी |

<p style="text-align: center;font-size:16px">कि सागर के छलकने से यह साहिल टूट जाते है ,
कि कश्ती का बिखर जाना उनकी रहमते होंगी |

<p style="text-align: right;">H.P.RAHI</p>`},
{date:"2009-05-20 07:57:55",name:"jangju ( Shayri )",body:`<p style="font-size:16px">कहा टिकता है कोई जर्रार  मेरे सामने ,  आज दिल जंगजू बहोत है |
रहेगा आसमान भी मेरे कदमो तले , आज परवाज बुलंद बहोत है | <span style="font-size:12px">H.P.RAHI</span>

<p style="text-align: center;">जर्रार - बहोत बड़ी सेना , परवाज़ - उडान |</p>`},
{date:"2009-05-24 17:50:28",name:"mausam..",body:`बदली मचल गयी है..
या कोशिश तेरी मेरे ओर आने की
याद दिला रहा है मौसम...
हमे उस बीते ज़माने की
इक पल बीता नहीं तेरे बिन..
आज खबर नहीं अरसा बीत जाने की
क्यूँ मानता नहीं सच्चाई को..
आस है आज भी तेरे घर आने की

DT`},
{date:"2009-05-24 18:00:51",name:"Phursat ( Shayri )",body:`<p style="font-size:16px">बहोत ढूंढता रहता हूँ कुछ पल फुरसत के लेकिन ,
वक़्त उदास सा किसी कौने में छुपा रहता है |
मुझसे नाराज़ है शायद |<span style="font-size:12px">H.P.RAHI</span>`},
{date:"2009-06-11 11:43:32",name:"harf ( Ghazal )",body:`<p style="font-size:16px">सहर तक आते आते हर पल को गिनता रहा ,
मेरे दिल अजीज एक ख्वाब ने सताया बहोत मुझे  |

<p style="font-size:16px">कभी कभी अपने भी जरा कुछ काम आ जाते है ,
मेरी ग़ज़ल से टूटकर एक हर्फ़  ने सिखाया बहोत मुझे |

<p style="font-size:16px">एक हवा रोज आखो में आ जाती है कुछ तिनके लेकर  ,
मेरे वतन ने हर तरह से पुकारा बहोत मुझे |

<p style="font-size:16px">दौड़ता रहा पीछे पीछे बहोत  दूर तक रेत पर ,
कुछ कदमो के निशानों ने थकाया बहोत मुझे |
<p style="text-align: center;">सहर - सुबह , हर्फ़ - अक्षर |</p>
<p style="text-align: right;">H.P.RAHI</p>`},
{date:"2009-08-19 15:37:24",name:"Salaam Jaipur ( Shayri )",body:`<p style="font-size:16px">इस शहर की हवाओ ने मुझसे गले लग कर कहा ,
देख जितने ख्वाब तू, मिल के हम पुरे करेंगे |
जिंदगी एक जश्न है और जश्न यह खुलकर करेंगे |<span style="font-size:12px">H.P.RAHI</span>`},
{date:"2009-09-23 13:03:22",name:"bahak jana baki hai ( Ghazal )",body:`<p style="font-size:16px">चले जाना के अभी इस रात का एक पहर बाकि है ,
चले जाना के अभी इस दिल का एक अरमान बाकि है |

<p style="font-size:16px">धड़कते दिल तड़पते है , मचलते दिल तरसते है ,
चले जाना के अभी इन सब का जल जाना बाकि है |

<p style="font-size:16px">जिस दिल को संभाले थे , हमने उसको खोया है ,
चले जाना के अभी इस नुकसान का हर्जाना बाकि है |

<p style="font-size:16px">तेरी आँखों के पैमाने पिए हमने नहीं पुरे ,
चले जाना के अभी किसी का बहक जाना बाकि है |
<p style="text-align: right;">H.P.RAHI</p>`},
{date:"2009-11-09 17:11:14",name:"Hum hi hum na rahe ( Shayri )",body:`<p style="font-size:16px">हाय उल्फत ए मुसीबत, जब भी पड़ी , भारी पड़ी ,
तुम तो आखिर तुम ही थे , हम ही हम न रहे | <span style="font-size:12px">H.P.RAHI</span>`},
{date:"2009-11-09 22:10:54",name:"kaise..",body:`पल पल याद का एहसास उन्हें दिलाएं कैसे
अपने ख्यालों की तस्वीर उन्हें दिखाएं कैसे
उनसे मिलने के बाद जुदाई को समझा है हमने
अपनी बेचैनी  को शब्द हम पहनाएं कैसे|

DT`},
{date:"2009-11-09 22:55:45",name:"thts time..",body:`सोचता हूँ ना सोचेंगे अब तुम्हें
हर चीज तेरी याद दिलाती है
जिस हंसी के लिए ज़िन्दगी थी हमारी
आज वही हंसी हमें रुलाती है|

DT`},
{date:"2009-11-11 18:43:03",name:"Renaissance Theme Song, Its a dream come true for me.",body:`<embed height="350" width="425" flashvars="file=http://www.uhooroo.com/uploads/1585_1256198339.mp3&amp;autostart=false&amp;displayheight=350&amp;image=http://www.uhooroo.com/uploads/1585_1256198339_c_flash.jpg&amp;overstretch=false&amp;bufferlength=10" pluginspage="http://www.macromedia.com/go/getflashplayer" type="application/x-shockwave-flash" src="http://www.uhooroo.com/fp/flvplayer.swf"/>

This is a theme song for Jecrc's annual fest Renaissance. Its a dream come true for me. I m attaching the Mp3 version of this performance. We have recorded it when we were practising it. If you want to see the stage performance of this song than go to this link
http://www.youtube.com/watch?v=o5VCZAEl1fA

I have wrote this song while I was attanding my first Renaissance. But It couldnt be converted into a complete song. Than after that failure. I wrote this song again in second year. Somehow this time was also the same story. Its very tough to get some composers and players who can actually create originals. and in the third year it happened again I wrote it again completely fresh. But It was also a failure. But wait I still have my last year. This time I found someone who really love the music to the core of his heart. Anshul Medatwal. My classmate who plays guitar extremly well. He also never performed on stage during this four years. So two hidden artists met and decided to burn the curtains. There was one more man who was in our team. Mr. Anoop Singh. He is a gem singer and also unsung during college time. Now first we composed the song completely and decided to sing it in chorous. We had one more singer with us. Abhishek Swami. This man has very potential. He has one more talent. He is a great dancer as well. He is in the team of Enigma Dance Group. But we need some more musicians. at least a drummer could make us complete. So we found a genius drummer. AAditya Patvardhan. He was the most talented guy in our team. We prepared the complete song and there it was the moment when our band was on the stage. My dream was about to be succeed. and jecrc find it cool. they liked it. some of them gave us Standing Owetion. When there was that loud sound of clapping I lived my dream in that moment. So that was my theme song story. :)

हमारी जान है ये , सबकी पहचान है ये ,
हमारा जूनून है ये , दिलो का सुकून है ये ,
हमारी तरह तुम अहसास करो ,
come on feel renaissance

हस्ते हुए मुंडेरों से गाती हुयी बहरे चली ,
येह आग है जली जली , मस्तानी हवाए हुयी ,
कोई नई झनक बजी , सुरों की नयी धनक है सजी ,
कोई नई झनक बजी , सुरों की नयी धनक है सजी |
come on feel renaissance

एक बूँद ख्वाब की टपकी जो आँख से ,
अहसास हो गया हमें , है जोश अब जान में ,
इस जोश को तू दे सिरे , बन जाये नए सिलसिले ,
बढा कदम तू एक बार , पायेगा नया आसमाँ | 
come on feel renaissance

तेरे लिए ही काफिले गुजरे है इसी रास्ते ,
हो चल तू संग संग लिए कुछ सपने अपने वास्ते ,
बदल जा तू अभी जरा , महक जा तू अभी जरा ,
है जो आग तुझमे तो दहक जा तू अभी जरा |
come on feel renaissance &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;H.P.RAHI`},
{date:"2009-12-03 10:33:14",name:"manhusiyat ( Shayri )",body:`<p style="font-size:16px">वोह रहगुज़र मेरी रहगुज़र रहे ,
साथ चलने से कुछ इत्तेफाक हो जाते है |
अपनी मनहूसियत की चर्चा करता फिरता हूँ ,
साथ चलने वाले कुछ करम कर जाते है |<span style="font-size:12px">H.P.RAHI</span>`},
{date:"2009-12-03 10:53:29",name:"hizr ( Shayri )",body:`<p style="font-size:16px">वोह जुबा पर क्यों ऐसे आता है ,
दिल जलाता है चला जाता है ,
उस दिलरुबा को जाने क्या कहिये ,
जो ख्वाब को भी हिज्र बना जाता है | <span style="font-size:12px">H.P.RAHI</span>
<p style="text-align: center;">हिज्र - जुदाई |</p>`},
{date:"2010-02-19 09:41:25",name:"uljhan",body:`कुछ अरसा बीत गया है..कुछ लम्हे रह गए हैं..
जाने इस ख़ामोशी में हम क्या कह गए हैं..
सुनते आये हैं लोगो से अक्सर ये हम..
वक़्त की करवट में बहुत दम है..
वक़्त ने बदल डाला इतना कुछ...
हम नहीं जाने, हम बदले या वही रह गए हैं|

DT`},
{date:"2010-02-23 08:55:04",name:"HPRAHI on IMDB.COM",body:`Hey Guys,

One great news from my side. I have been registered as a lyricist on world's biggest and most authentic International Movie Database "IMDB". Its for a film "An affair with newyork". This film also won "Best Film Critic" in JIFF (Jaipur International Film Festival). 

 <a href="http://www.imdb.com/name/nm3797908/" target="_blank">http://www.imdb.com/name/nm3797908/</a>

The song for which I am on IMDB is from film "An affair with newyork"

अनजानी राहों पे है अनजानी मंजिले ,
तू पाने चला क्या भला ,
कोरी हथेलियों पे कहानी लिखी है जो ,
कौन सुनाएगा भला ,
है नया सा कारवा , और मुसाफिर नया तू यहाँ ,
तो करले , करले सपने जवा ,
एक कोशिश नयी , नयी दास्ता |

एक ख्वाब की कोशिश नए सिरे पाने की ,
कुछ अनसुनी सी धुन मेरी सुना जाने की ,
धुआ धुआ सी रौशनी मेरी दहकने लगी ,
तो करले , करले सपने जवा ,
एक कोशिश नयी , नयी दास्ता |

इस भीड़ में मिला मुझे हँसी हमसफ़र ,
आसा हुयी मुश्किलें , जीने लगा यह सफ़र ,
दबी दबी सी आरज़ू , मेरी धडकने लगी ,
तो करले , करले अरमा जवा ,
एक कोशिश नयी , नयी दास्ता | H.P.RAHI`},
{date:"2010-03-16 01:00:27",name:"baras..",body:`बरस बरस के बरस गया..
तुझसे मिलने को दिल तरस गया..
जानता हूँ आस पास ही है तू कहीं..
युहीं बीत जाता है एक बरस नहीं..
नजदीकिया नहीं अब मुझे तू साथ दे..
जिसके लिए हूँ तरसा अब वो मुलाक़ात दे|

DT`},
{date:"2010-03-17 10:09:46",name:"uuns ( Shayri )",body:`<p style="font-size:16px">उन्स उठते है तो जिगर के छाले मेरे रो पड़ते है ,
के आग बुझाकर मैं उनपे राख मल देता हूँ ,
मेरी जिंदगी का रंग बहोत धूसर सा लगता है मुझे | <span style="font-size:12px">H.P.RAHI</span>`},
{date:"2010-04-24 01:11:31",name:"shaam ( Ghazal )",body:`<p style="font-size:16px">शाम , पलकों में डूबी एक किताब सी लगती है ,
पत्तो पे जिसके ढलकी , शायद कुछ शराब सी लगती है |

<p style="font-size:16px">शाम , घायल अश्को से भी घायल लगती है ,
गहराई से देखा तो यह एक हिसाब सी लगती है |

<p style="font-size:16px">शाम , पागल दिल सी पागल , किसी बोजिल शबाब सी लगती है ,
बुझते हुए चिराग से भड़की जलती छाव सी लगती है |
<p style="text-align: right;">H.P.RAHI</p>`},
{date:"2010-05-24 03:45:11",name:"phir nayi phursate ( Ghazal )",body:`<p style="font-size:16px">एक ख्वाब की बख्शीश को संभाले , फिर नयी फुरसते ...


<p style="font-size:16px">घूमते रहें एक रात ओढ़े हुए चुपके से ,
यादो की गलियों से कुछ कदमो के निशान चुराए |
फिर नयी फुरसते...


<p style="font-size:16px">चाँद बांधे मुट्ठी में तारो से जगडा किये ,
कुछ टुकड़े चांदनी के धुप में सुखाये |
फिर नयी फुरसते...


<p style="font-size:16px">फिर उन्ही पानियों में हम नमक थामे रहे ,
लम्हों के वादे, सदियों में निभाए |
फिर नयी फुरसते...
<p style="text-align: right;">H.P.RAHI</p>`},
{date:"2010-06-01 19:15:31",name:"shauk ( Shayri )",body:`<p style="font-size:16px">तेरे दरे आस्ता , सबब बेकसी मेरी ,
गर मैं तेरी जरूरत नहीं , तो तेरा शौक ही सही | <span style="font-size:12px">H.P.RAHI</span>`},
{date:"2010-07-07 18:43:59",name:"thaka thaka ek rahi hoo main ( Ghazal )",body:`<p style="font-size:16px">एक सपना देखा , कितना वोह अच्छा सपना था |
कुछ अपना भी देखा था , कितना वोह सच्चा अपना था |

<p style="font-size:16px">ख़त कितना सुहाना था , कागज कुछ पुराना था ,
कुछ धुंधला सा कुछ उभरा सा , वोह तेरा इकरारनामा था |
बहता बहता एक आसू आया , पोछ गया कुछ स्याह को ,
कितना बेरहम यह आसू था , ले गया तेरे दिल को ,
एक यह दिल ही तो मेरा अपना था , भले कागज़ पे ही था जो ,
और तो सब कुछ धुआ धुआ सा सांस दे गया तेरे तन को |

<p style="font-size:16px">बरबस कही एक पत्ता पाया ,
पत्ते पे कुछ अपना पाया |
मिलो उडी हवा की छाया ,
छाया में भी खुद को पाया |
राख राख एक अंगारा था ,
उडी हवा तो वोह भी छाया था |
एक बादल मस्त बदन का पाया ,
वोह भी बरसो से ना बरसा था |

<p style="font-size:16px">इन अपनों का मिश्रण हूँ मैं ,
इन सपनो का जीवन हूँ मैं ,
वीरान मंजिल के ढांचे पर बैठा ,
थका थका एक राही हूँ मैं |
<p style="text-align: right;">H.P.RAHI</p>
`},
{date:"2010-07-18 15:29:29",name:"jafa ( Shayri )",body:`<p style="font-size:16px">जफा की राह में हम तो वफाओ से गए ,
दिलजलो से मिल गए और दिल जला के आ गए | <span style="font-size:12px">H.P.RAHI</span>`},
{date:"2010-08-09 01:28:10",name:"deewana ( shayri )",body:`<p style="font-size:16px">वो कहते है , ग़ज़ल से दोस्ती अच्छी नहीं "राही" ,
पर मैं अगर शायर न होता तो दीवाना होता | <span style="font-size:12px">H.P.RAHI</span>
`},
{date:"2010-08-18 19:56:48",name:"savan ( shayri )",body:`<p style="font-size:16px">याद आती है वोह आग बहोत , जिसे बुझे हुए ज़माने हो गए ,
के कभी सावन बीत जाया करता था हर बरस , और अंगारे फिर भड़क उठते थे | <span style="font-size:12px">H.P.RAHI</span>`},
{date:"2010-08-18 21:26:41",name:"hasarato ke chirag ( shayri )",body:`<p style="font-size:16px">बस एक  उल्फत ने धुआ कर दिए मेरे हसरतो के चिराग ,
कभी तूफ़ान से कश्ती बचाना इतना आसान तो न था  | <span style="font-size:12px">H.P.RAHI</span>`},
{date:"2010-08-27 00:56:42",name:"aznabi shaam..",body:`कॉफ़ी के तो बहुत ठिकाने हैं, बस शाम ने ही बात करना छोड़ दिया हमसे..
ज़िन्दगी बस दौड़ते जा रही है, यूँ तो ढून्ढ रहे हैं हम खुदको कबसे|

-DT`},
{date:"2010-10-01 02:32:35",name:"sham bhujaye hue ( Ghazal )",body:`<p style="font-size:16px">अरसा बिता है किसी की याद सताए हुए ,
अब भी रखता काश मैं तूफान जगाये हुए |

<p style="font-size:16px">उसकी सोहबत में नज़ारे हसीन लगते थे ,
उसके पहलु में मुझे चाँद सितारे करीब लगते थे
अब तो जैसे सन्नाटो के शोर गूंजते रहते है
मुझको भी तो युग बीते  आवाज़ लगाये हुए

<p style="font-size:16px">अजीब दौर था , मुहोब्बत की बात करते थे,
आग के दरिया में किनारों की बात करते थे,
न जाने तिश्नगी कैसे बुझा लेती है खुद ही प्यास
जलता रहता हूँ हर रोज़ एक शाम बुझाये हुए |
<p style="text-align: center;">तिश्नगी - प्यास |</p>
<p style="text-align: right;">H.P.RAHI</p>`},
{date:"2010-10-21 23:39:55",name:"ek tanha khayal..",body:`तनहाई को साथ रखना चाहिए हमेशा..
क्या पता कब किसी से बात करने की जरुरत पड़ जाए|

DT`},
{date:"2010-12-07 13:02:44",name:"karvat ( shayri )",body:`<p style="font-size:16px">काली अँधेरी रात, औंधी पड़ी हुयी ,
किस ओर सवेरा ? करवट ले तो जाने |<span style="font-size:12px">H.P.RAHI</span>`},
{date:"2010-12-25 13:54:29",name:"badnaam kinaare ( Ghazal )",body:`<p style="font-size:16px">गुमनाम बहारो की गुमनाम मजारे है ,
बरबस ही मिले है जो , गुमनाम सहारे है |

<p style="font-size:16px">छुपते हुए देखा है हमने कुछ अपनों को ,
महरबान अंधेरो में महरबान दरारे है |

<p style="font-size:16px">इक आह सी उठती है , एक दर्द सा जाता है ,
बेईमान किरदारों के बेईमान नज़ारे है |

<p style="font-size:16px">रोते हुए पाया है कश्ती के इरादों को ,
बदनाम समंदर के बदनाम किनारे है |
<p style="text-align: right;">H.P.RAHI</p>
`},
{date:"2010-12-28 01:40:39",name:"Ahmiyat",body:`<p style="font-size:16px">रास्ता था , जाने मुकाम , एक दहलीज थी शायद ,
उससे पार होके कई गुजर गए , कई लौट गए ,
वक़्त की कमी न थी तो तमाशा देखने लगे वही ,
पार जाने की हिम्मत जुटाते जुटाते रात हो गयी ,
तो वही सो गए मील के पत्थर का सहारा लेकर ,
सुबह उठे तो देखा न वोह देहलीज़ थी न मुकाम ,
रास्ते का भी नामो निशाँ तक न था ,
पर वोह मील का पत्थर वही का वही था ,
फासले के निचे कुछ लिखा था लाल रंग से ,
अहमियत रास्तो की है या मंजिल की ,
कुछ देर रुका , सोचा और फिर आगे बढ़ चला |
<p style="text-align: right;">H.P.RAHI</p>`},
{date:"2011-01-23 18:19:12",name:"thikane ( Ghazal )",body:`<p style="text-align: center;font-size:16px">लम्हा लम्हा जिंदगी के आशियाने बीत गए ,
दीवानगी में बेखुदी के वो ज़माने बित गए |

<p style="text-align: center;font-size:16px">ले जा रहे हो क्या यहाँ से ? क्या मिला होगा तुम्हे ?
दीदावारे हुस्न के , मयकशी के , वोह ठिकाने बित गए |

<p style="text-align: center;font-size:16px">जर्रा जर्रा जोशे जूनून खो गया , गुम हो गया ,
ए दिल तेरी जिन्दादिली के , वोह फ़साने बित गए |

<p style="text-align: center;font-size:16px">बेनाम तिशनगी सताए , दिल जलाये सहरा सहरा .
ए समंदर आज तेरे वोह नज़ारे बित गए |
<p style="text-align: center;font-size:16px"></p>

<p style="text-align: center;">बेखुदी - नशे में , दीदावर - दर्शन , तिशनगी - प्यास , सहरा - रेगिस्तान |
<p style="text-align: right;">H.P.RAHI</p>`},
{date:"2011-01-27 01:47:57",name:"syah lamhe",body:`<p style="font-size:16px">नशे में मैंने चाँद तोड़कर अपनी डायरी में छिपा दिया ,
चाँद चांदनी परोस रहा था, और मैं पि रहा था उस रात तन्हा |
सुबह उठकर देखा तो उस सफ्हे पर एक नज़्म रोशन थी ,
हर अशआर में कैद लम्हे ताक रहे थे मुझे ,
पेन की निब से निकले यह लम्हे , जब जिए थे मैंने , इतने स्याह तो न थे |
<p style="text-align: right;">H.P.RAHI</p>`},
{date:"2011-01-28 20:50:44",name:"ik baat..",body:`<div>जिनकी बात, जिनसे बात, थी रोज़ कि बात..</div>
<div>उनसे दुआ सलाम का ठिकाना नहीं..</div>
<div>जिनसे बात करते है हम आज रोज़..</div>
<div>क्या वो भी वक़्त के कुछ लम्हे हैं?</div>
<div></div>
<div>DT</div>`},
{date:"2011-03-04 02:35:59",name:"nazar ( shayri )",body:`<p style="font-size:16px">उठी वोह नज़र के बहोत इब्तेदा से यु ,
अशआर बने ग़ज़ल और ग़ज़ल किताब हुयी |<span style="font-size:12px">H.P.RAHI</span>`},
{date:"2011-03-04 02:41:35",name:"ek katil ( Ghazal )",body:`<p style="text-align: center;font-size:16px">उठा धुआ तक नहीं जब दिल ये मेरा खाक हुआ ,
तेरी शान में गुस्ताख थी चिंगारी , जो सजा ए बेनूर हुयी |

<p style="text-align: center;font-size:16px">कातिल का पता जानते हो तो पूछते क्यों हो ,
ए दर्दे जिगर मेरे, सो जाओ के बहोत देर हुयी |

<p style="text-align: center;font-size:16px">शबे फुरकत में रिंद , बहोत पीना नहीं अच्छा ,
कट जाएगी यह रात , जो बेहोश न भी हुयी |

<p style="text-align: center;font-size:16px">आईने तोड़ के बैठा हूँ एक पत्थर पे रक्खे सर ,
परछाई से जगडी के हाय , नज़र धुंधली हुयी |
<p style="text-align: center;">शबे फुरकत - जुदाई की रात , रिंद - शराबी |
<p style="text-align: right;">H.P.RAHI</p>`},
{date:"2011-03-16 20:51:58",name:"Kiski Kahani - Trailor",body:`Hi friends,
Here it is, my first film's first promo!!! Tell me how do you like it?

<iframe title="YouTube video player" width="508" height="316" src="http://www.youtube.com/embed/a42ZbR0NcVY" frameborder="0" allowfullscreen></iframe>

Visit - <a href="http://www.kiskikahani.com">http://www.kiskikahani.com</a> for more details.`},
{date:"2011-03-26 00:44:52",name:"tu hai tu kahin to hai",body:`  
<div>तू है तू कही तो है..या ये सिर्फ तेरी आस है</div>
<div>तू है तू कहीं तो है..लगता है तू आस पास है</div>
<div>तू है तू कहीं तो है.. मेरा एक एहसास है</div>
<div>तू है तू कहीं तो है..इक तू ही तो मेरी ख़ास है|</div>
<div>DT</div>`},
{date:"2011-03-26 00:56:36",name:"pata na chala..",body:` 
<div>इक तेरे इंतज़ार में दर पे दिया जलाये बेठे हैं..</div>
<div>इंतज़ार कुछ इतना हुआ, हुआ दिया हवा पता न चला..</div>
<div>लोग कहते हैं वो न आयेंगे कभी..</div>
<div>ऐसा क्या गुनाह हुआ, हुआ जो पता न चला|</div>
<div>ज़िन्दगी चल रही, कट रही है शायद.</div>
<div>तेरी याद में तेरे इंतज़ार में..</div>
<div>बदला नहीं कुछ भी तेरी गैरमौजूदगी में</div>
<div>बदला जो हुआ तो पता न चला||</div>
<div></div>
<div>DT</div>
`},
{date:"2011-03-27 17:53:58",name:"tu bekhabar ( Ghazal )",body:`<p style="text-align: center;font-size:16px">हर नफस मेरी एक जलन , तु बेखबर ,
कितना धड़के दर्दे जिगर , तु बेखबर |
<p style="text-align: center;font-size:16px">एक जान मेरी , कब है मेरी ,
तु जाने न , तु बेखबर |
<p style="text-align: center;font-size:16px">तेरी एक नज़र , तीरे नीमकश ,
तेरे दीद का इतना असर , तु बेखबर |
<p style="text-align: center;font-size:16px">गाफिल "राही" , एक ही तरफ ,
तेरी रहगुज़र , तु बेखबर |
<p style="text-align: center;font-size:16px">इतनी बढ़ी , के कुछ और हुयी ,
दोस्ती तेरी , तू बेखबर |
<p style="text-align: right;">H.P.RAHI</p>`},
{date:"2011-04-12 14:06:46",name:"himayat ( shayri )",body:`<p style="font-size:16px">फिर कभी चोरी नहीं करूँगा, वादा है ,
दर्द तेरा था और चुभ सा गया था मुझे ,
पर जब भी लिखने बैठता हूँ , तो वोह लम्हा ,
जिसकी किर्चिया आज भी मेरे जहन में मौजूद है ,
फिर उसी दीवानगी की हिमायत करता है |<span style="font-size:12px">H.P.RAHI</span>`},
{date:"2011-05-10 18:56:42",name:"tera muslman hona",body:`<p style="font-size: 16px;">आज कुछ पुराने ख़त टटोल रहा था ,
जिंदगी की ताजगी से भरी उन यादो को ,
फिर से जीने चला था |
कितने रंग बिखरे हुए थे उन सफ़ेद कागजो पर , जिंदगी कितनी रंगीन थी ,
कभी तेरी शिकायत ने मुझसे नज़र भर ली ,
कभी रुलाई तेरी मेरे लबो पे इनाम बन गयी ,
और कभी तेरे खून के धब्बे देख मेरी सासे तेज़ हो गयी ,
कितनी शिद्दत थी जो भी करते थे , मौसिकी हो जैसे सास लेना भी ,
लबरेज़ ख्वाबो से चश्म , दिखाई भी वही देता था जो चाहते थे ,
ज़माने भर का ज़ज्बा अपनी जेब में लेकर महोब्बत करते थे |
कुछ चुभा शायद , एक चूड़ी का टुकड़ा छुपा बैठा था उस कागज़ के पीछे ,
एक दाग तेरे खूं के आगे मेरा भी लग गया , लाल से लाल मिल गया ,
और एक ज़ख्म फिर से हरा हो गया |
मेरे गुनाह का भागिदार यह समाज था भी, नहीं भी ,
वोह बात अनसुनी कर देता तो क्या बिगड़ जाता ,वैसे भी अब कहा कुछ सुनाई देता है ,
धड़कन से लेकर ज़मीर तक की आवाज़ दब के रह जाती है मेरे दंभ के शोर में ,
इस समाज के ठेकेदारों में अब मैं भी तो गिना जाता हूँ |
तुझसे वोह आखरी मुलाकात अब भी धुंधली नहीं हुयी मेरे जहन में ,
मेरी हमनफस मुझे माफ़ करना , तेरे कद से मेरी लम्बाई छोटी पड़ गयी थी ,
सारा साहस बस उस शाम दरिया में बह गया था ,
तेरा मुसलमाँ होना मेरी महोब्बत पर भारी पड़ गया था |</p>
<p style="text-align: right;">H.P.RAHI</p>`},
{date:"2011-06-17 00:57:53",name:"do bhatke hue musafir",body:`<p style="font-size: 16px;">दो भटके हुए मुसाफिर ,
उस मंजिल की ओर , जिसका न कोई साहिल न कोई छोर ,
जाने क्या देखते , क्या सोचते , क्या गाते हुए बढे जाते है ,
दो भटके हुए मुसाफिर ,
साथ है फिर भी , एक डोर से बंधे ,
किसी रिश्ते की डोर तो नहीं लगती है लेकिन ,
किस धागे की डोर है ये फिर ?
शायद किसी कसम की ,
साथ चलने की कसम , उस अनजान मंजिल की ओर ,
जहा पर कुछ मिले भी तो शायद , साथ के सिवा कुछ न मिले ,
दो भटके हुए मुसाफिर ,
हवाओ पे पाँव रखते , बादलो के पार चले ,
आसमान के आगे , चाँद के पार चले ,
उन्हें वोह आग का दरिया चाँद के पार ही मिल जाये शायद ,
वोह आग का दरिया ही इनकी मंजिल हो |
कोई कहता है , इश्क एक आग का दरिया है और डूब के जाना है ,
पर हमें तो इश्क के सिवा कही नहीं जाना है ,
बस इसी दरिया में ही डूब जाना है |
दो भटके हुए मुसाफिर ,
उस मंजिल की ओर , जिसका न कोई साहिल न कोई छोर |</p>
<p style="text-align: right;">H.P.RAHI</p>`},
{date:"2011-06-22 20:19:16",name:"gubaar (गुबार)",body:`<p style="font-size: 16px;">जब बारिश में भीगते हुए भी जिया जलता है ,
और रिम जिम जब धुआ भी उठने नहीं देती ,
तब कुछ असीर अरमानो की बेडिया टूटने लगती है ,
और याद आता है वोह ख्वाब ,
जिसे बुनते मुझे पुरे दो साल लगे थे ,
और टूटने में वोह कुछ पल ,
ओ साथी रे , दिन डूबता है , रात होती है ,
तू होती नहीं , फिर भी होती है ,
तेरा जाना होता ही नहीं कभी |</p>

<p style="font-size: 16px;">इस "राही" का है सफ़र तनहा ,
और सिफर इसकी मंजिल ,
यु मंजिल का भरम मत बन ,
बारिश में जिया जलने की सजा तो न दे |</p>
<p style="text-align: center;">असीर - कैद , सिफर - शुन्य |</p>
<p style="text-align: right;">H.P.RAHI</p>`},
{date:"2011-06-25 01:06:23",name:"raat ki ek baje",body:`<p style="font-size: 16px;">क्यों ग़मज़दा है हर बात आज रात की ,
क्या अब भी बरक़रार है वोह शाम की बारिश ,
जो तिनको की तरह आखो में चुभी जा रही थी |
न जाने क्यों , वो शाम ढल गयी , आधी रात भी हो गयी ,
क्यों ढूंढता रहता हूँ तुझे चेट लिस्ट में रात की एक बजे ,
तू तो कबकी जा चुकी ,
आशा करता हूँ तेरा तकिया ,
मेरी पिछली नज़्म वाला चाँद होगा |
पलटना तेरा जरूरी क्यों था ,
क्यों मेरा ऐसा सोचना जरूरी है ,
कई सवाल है , कोई जवाब भी होगा ,
आखो के आईने पे नोट कर लिए है ,
तुझसे पूछुंगा , जब मिलूँगा ,
पढ़ लेना , अगर आँखे मिल जाये तो |</p><p style="text-align: right;">H.P.RAHI</p>`},
{date:"2011-06-29 00:48:14",name:"yaad",body:`<p>तू याद बहोत आया कल रात ,
और रात थी जो ख़तम ही न हुयी |</p>

<p>ऐसे सितम सहता हूँ हर रात ,
और एक तेरी आमद है , हर बार टलती रही |</p>

<p>एक आहट से भी तेरी जो सुकून मिलता था मुझे ,
वोह आहटे ही मेरे दिल का घाव हुयी |</p>

<p>थोडा और , थोडा और , थोडा और इंतज़ार ,
कुछ ही सासों की बात है , अटकी हुयी | Deeps</p>`},
{date:"2011-07-01 03:33:28",name:"aapbiti",body:`<p style="font-size: 16px;">रोने दो ना ,
नज़र उठा के देखा तो को दरवाज़े में चटखनी नहीं थी ,
बंद नहीं होता है साला , अब रोऊ कैसे ?
गला सुख रहा है ,
फ्रीज़ खोल कर देखा ,
तो पानी की एक बूंद तक न थी ,
बस २ बीयर की बोटल , भरी हुयी |
पिने दो ना |
कंप्यूटर पे बैठा हूँ मैं इस वक़्त ,
उस फोल्डर तक ना जाने कैसे , लेकिन पहोच गया ,
जहा पर कैद है एक हिडन जुस्तजू-ए-जिंदगी ,
डिलीट तक हड्डिया जाती नहीं ,
 प्लीज़, रहने दो ना |H.P.RAHI`},
{date:"2011-07-01 19:44:20",name:"maji",body:`<p style="font-size: 16px;">नाज़ुक मोड़ था , उसने तो बस अपना साया छुड़ा लिया ,
और मेरे हिस्से का सूरज देखता रहा , शीशे में ताकता रहा |
अब भी सुबह की धुंध में कुछ शेरो के माजी उभरते है ,
और रात वाली नज़्म जब दिनों तक साथ चलती है ,
आखों की कोरे जलती रहती है , नींदे सिर्फ सफाई देती है ,
तेरे साये का पीछा करते करते मेरे हिस्से का सूरज ,
हाफ्ने लगता है , तब बदन की तपिश से मैं चौक उठता हूँ ,
और सोचता हूँ , वक़्त की चारागरी भी क्यों काम नहीं आई ?
तुने तो बस अपना साया ही छुड़ाया था , नाज़ुक मोड़ था ,
एक कस्तूरी अभी भी मेरे पास है , जिसमे तू कैद है ,
शायद यह अंधी दौड़ है , और मुझे दौड़ते जाना है ,
मेरे हिस्से के सूरज की छाव तले |</p>
<p style="text-align: right;">H.P.RAHI</p>`},
{date:"2011-07-22 09:59:20",name:"Kiski Kahani - My first film",body:`Yes I have made a short film and its releasing today. for more information about movie, please visit - <a href="http://www.kiskikahani.com">http://www.kiskikahani.com</a>. You can watch and download full movie there. :)`},
{date:"2011-07-27 22:27:49",name:"khwahishe ( shayri )",body:`<p style="font-size:16px">कभी जीते थे मुफलिसी में ,
अब भी जीते है मुफलिसों  से ,
हाये , हजारो ख्वाहिशे ऐसी | <span style="font-size:12px">H.P.RAHI</span>
<p style="text-align: center;">मुफलिसी - गरीबी |</p>`},
{date:"2011-08-02 18:41:14",name:"parivartan ( shayri )",body:`<p style="font-size:16px">परिवर्तन मुझे कभी पसंद नहीं आया ,
यह बात अलग है के आदत पड़ ही जाती है कुछ रोज़ बाद ,
समय की चारागरी मुझ पर भी असर रखती है | <span style="font-size:12px">H.P.RAHI</span>`},
{date:"2011-08-04 15:19:40",name:"ehsaan ( Ghazal )",body:`<p style="text-align: center;font-size:16px">शाम मुझपे एहसान करती है ,
रोज़ मिलती है जुदा होती है |
<p style="text-align: center;font-size:16px">यु सताती है मुझे हर साँस मेरी ,
नाम लेती है और बदनाम होती है |
<p style="text-align: center;font-size:16px">क्या वफ़ा ,  क्या उम्मीद और क्या ज़ज्बात ,
इससे बेहतर भी कोई सजा होती है ?
<p style="text-align: center;font-size:16px">यु दिखती नहीं उजालो में नजारों की तरह ,
यह शमा , दिल के आइनों में अक्स होती है |
<p style="text-align: right;">H.P.RAHI</p>`},
{date:"2011-08-26 20:24:27",name:"Mehfooz ( Shayri )",body:`<p style="font-size:16px">कुछ इस तरह बह गए हम आखो से खू बनकर ,
तेरे तसव्वुर में रहते भी तो क्या महफूज़ होते ? <span style="font-size:12px">H.P.RAHI</span>`},
{date:"2011-09-10 14:24:35",name:"ek baat yu huyi ( Ghazal )",body:`<p style="text-align: center;font-size:16px">एक बात यु हुयी ,
सहर के वक़्त शाम हुयी |

<p style="text-align: center;font-size:16px">वोह धुप का सामान निकला ,
उस और मुड़ा और रात हुयी |

<p style="text-align: center;font-size:16px">एक ख्वाब सरे आम हुआ ,
एक जुस्तजू नीलाम हुयी |

<p style="text-align: center;font-size:16px">जब धड़कन की साँस चली ,
एक आह उडी , और राख हुयी |
<p style="text-align: right;">H.P.RAHI</p>`},
{date:"2011-09-11 17:51:56",name:"inayat ( Shayri )",body:`<p style="font-size:16px">तेरी पहली शिकायत मुझसे , एक शरारत की तरह ,
मुझपे असर अब तलक , एक इनायत की तरह | <span style="font-size:12px">H.P.RAHI</span>`},
{date:"2011-09-14 23:37:24",name:"ab talak",body:`<p style="font-size: 16px;">जब भी देखता रहता हूँ तारे आसमानी कालिख में ,
कुछ नीली सी याद उस ओर से गुज़रती है ,
जहाँ नदी के दोनों छोरो पर दो पत्थरो जैसे इंसान ,
हां पत्थरो से ही थे वोह ,
चुप चाप बैठे है ,
इसी आसमानी कालिख के साये तलक ,
शाम से रात का सफ़र करते है ,
नदी बहते हुए भी थमी सी रहती है वहा ,
जैसे वक़्त थमा हो सफ़र कर  के भी ,
यु शाम से रात होना , नीले का कालिख होना ,
मेरी रूह में दो छोरो पे अटका है |
मेरा रंग अब तलक तेरे रंग से मिलने की फरियाद में है |</p>
<p style="text-align: right;">H.P.RAHI</p>`},
{date:"2011-10-12 09:21:59",name:"kaisa lagta hai",body:`<p style="font-size: 16px;">तुम आग में जले हो कभी , तुम बार बार मरे हो कभी ?
तुम जब कही नहीं थे तब कहा पर थे ?
कौन राह चले थे जब रुके रुके से थे ?
ख़ामोशी को सुन कर देखा है, कैसा लगता है ?
यह अँधा कुआ झाका है कभी , कैसा लगता है ?</p>

<p style="font-size: 16px;">सन्नाटो के शहर में आकर , धुन कोई छेड़ी है कभी ?
पत्थरो के जंगलो में नदी बनकर बहे हो कभी ?
आसान से जो नज़र आते है , इरादे जिए है कभी ?
कभी सूरज को पीकर देखा है , कैसा लगता है ?
कभी चाँद चुरा के देखा है , कैसा लगता है ?</p>

<p style="font-size: 16px;">जब जंग छिड़ी थी बागीचे में , तुमने भी कुछ फूल तोड़े थे ,
कुछ सफिने तुमने भी डुबोये थे , कुछ राहे तुमने भी तनहा छोड़ी थी ,
क्या सोचा है कभी , उस पार क्या बिता है वक़्त अब भी ?
रूठे हुए कुछ साये , सूखे हुए पत्तो जैसे कुछ साये ,
जिन्दा साये , मुर्दा साये , उस पार क्या बिता है वक़्त अब भी ?
कभी मुड़कर पीछे देखा है , कैसा लगता है ?
कोई छोटा सा वादा भी जीकर देखा है , कैसा लगता है ?</p>
<p style="text-align: right;">H.P.RAHI</p>`},
{date:"2011-12-12 21:55:57",name:"ek khwab hi to hai",body:`<p style="font-size: 16px;">एक ख्वाब ही तो है ,
रात कट जाती है मेरी , तेरा क्या जाता है ?
क्यों हर बार आकर शीशे तोड़ जाता है ?</p>

<p style="font-size: 16px;">जब होता हूँ उस मोड़ पर ,
जहा से रेल की पटरी गुज़रती है ,
जिस पर कभी एक ट्रेन गुजरी थी ,
किसी मुसाफिर की आमद का वक़्त भी हुआ था ,
हर बार सोचता हूँ , हवा के पंख पकड़ कर ,
चंद मील मैं भी सफ़र कर लू ,
मैं हाथ बढ़ाता हूँ तो तेरा क्या जाता है ?
क्यों हर बार आकर शीशे तोड़ जाता है ?</p>

<p style="font-size: 16px;">रात को जब उजाले जागते है ,
तब एक दूर जाते खुशबु से साए का ,
देर तक का पीछा करते करते ,
कुछ दहलिज़े ज़माने के पार जाता हूँ ,
कभी कभी चाँद के उस ओर भी जाता हूँ ,
तेरे बदन तक वक़्त भर में पहोच पाता हूँ ,
तेरी बाहों तक आता हूँ तो तेरा क्या जाता है ?
क्यों हर बार आकर शीशे तोड़ जाता है ?</p>

<p style="font-size: 16px;">कभी कही नहीं जा पाता , कभी दूर तक निकल जाता हूँ ,
एक ख्वाब ही तो है , तेरा क्या जाता है ?</p>
<p style="text-align: right;">H.P.RAHI</p>`},
{date:"2011-12-19 21:27:02",name:"Kiski Kahani in JIFF",body:`My First Film "Kiski Kahani" got selected in Jaipur International Film Festival (JIFF).
<br>
Check this link - <a href="http://www.jiffindia.org/documents/2nd%20%20List%20of%20Nominated%20films-JIFF%202012.pdf">http://www.jiffindia.org/documents/2nd%20%20List%20of%20Nominated%20films-JIFF%202012.pdf</a>`},
{date:"2011-12-21 15:45:54",name:"salaam jaipur 2",body:`Wrote after coming back to jaipur from kashmir trip in summer'11<br>
<p>हम पहुचे थे बड़ी जिद के साथ वादी ए कश्मीर ,
बहोत खुबसूरत है माना हमने, वादी ए कश्मीर ,
मगर हमें तो ज़िन्दगी की नमी इन लू के थपेड़ो में ही वापस मिली |H.P.RAHI</P>`},
{date:"2012-03-05 00:35:55",name:"Remembering Jagjit Singh JI",body:`Remembering Jagjit Singh JI at Sirifort Auditorium, Delhi with Gulzar, Bhupinder and Mitali.<br>  
वोह लम्हा जो धुंधला अब भी मौजूद है ,
कोई धकेल देता है उसे जब आगे ,
मेरे रोंगटे खड़े हो जाते है उस बार की तरह ,
आज भूपिंदर की आवाज़ मुझे फिर वही ले गयी ,
जहा तुमने सिर्फ गुनगुनाया था थोडा सा ,
और पब्लिक ने तुम्हे आगे गाने न दिया ,
खुद गाया था , "होठो से छु लो तुम",
एक रूहानी सफ़र था वोह ,
और हजारो ने साथ निभाया था | H.P.RAHI`},
{date:"2012-03-06 22:38:44",name:"yaado ke gulmohar",body:`<p style="font-size: 16px;">माजी जाने कहा कहा से ले आता है ,
यादो के गुलमोहरो से लम्हों की शाखे तोड़कर ,
आदत में है आजकल इसके ,
हाथो में इसके कही से एक कुल्हाड़ी आ गयी है ,
गुलाबी,
एक किताबी संदूक में छिपाकर रखी थी ,
मैं बस टहनीया सुखाकर जला देता हूँ ,
रात पश्मीने की तनहा बसर नहीं होती |</p>
<p style="text-align: right;">H.P.RAHI</p>`},
{date:"2012-03-12 02:10:09",name:"shahar wala chand",body:`<p style="font-size: 16px;">मेरे शहर की रात वाला , तनहा तनहा चाँद ,
कुछ देर पहले देखा था ,
मेरे घर के सामने एक मंदिर के गुम्बद से लटक रहा था ,
अब रोड के किनारे वाली मस्जिद की छत पर आ गिरा है ,
वहा से लुढ़केगा जरूर कुछ देर में ,
जमीन पर आ गिरेगा तो मैला हो जायेगा ,
सोचता हूँ बुझा दू रात और बचा लू चाँद को ,
या गिरने दू , और छिपा के घर ले आऊ ,
रख लू एक दिन के लिए ,
कल शहर के उजालो से बहोत दूर,
तारो की हिफाज़त में दे आऊंगा , वहा दोस्तों के बीच रहेगा |</p>
<p style="text-align: right;">H.P.RAHI</p>`},
{date:"2012-03-31 15:46:19",name:"fakiran",body:`<p style="font-size:16px">रात में ओढ़े चांदनी , दिन में सूरज चन्दन ,
दाना दाना पेट का जशन , और तिनका नशेमन ,
मौला , करम पे तेरे एक फकीरन , नसीबन |<span style="font-size:12px">H.P.RAHI</span>`},
{date:"2012-04-03 12:58:07",name:"khajana",body:`<p style="font-size:16px">उडी है धुप , उस गुफा की तरफ , जिसका नाम दिल पड़ा था ,
तेरे नाम से जब भी सहर खोलता हूँ मैं ,
कुछ दिल के खजाने मैं कैद करता हूँ मैं |<span style="font-size:12px">H.P.RAHI</span>`},
{date:"2012-05-04 19:35:35",name:"dhup ke afsha",body:`<p style="font-size:16px">चाँद का वज़न कुछ बढ़ा हुआ सा मालूम होता है ,
आज फिजा में तपिश कुछ ज्यादा थी ,
दिन भर धुप के अफशाओ की खुराक ज्यादा हो गयी होगी|<span style="font-size:12px">H.P.RAHI</span>
<p style="text-align: center;">अफशा - पकवान |</p>`},
{date:"2012-06-12 16:02:48",name:"ulfat ke samaan",body:`<p style="font-size:16px">तेरे शहर में कैसे कैसे उल्फत के सामान मिले ,
जर्द सी गलियों में बेजा जिस्मो के औजार मिले |<span style="font-size:12px">H.P.RAHI</span>`},
{date:"2012-07-06 02:20:57",name:"Tuj bin kaise? kyo? kisliye?",body:`<p style="font-size: 16px;">तुज बिन कैसे? क्यों? किसलिए?
वादे पे क्यों? जिए मरे रोये ?
कोई ख्याल , क्यों सताए? किसलिए?
आसू सूखे तो खून क्यों रोये ?

<p style="font-size: 16px;">ढूंढा किये समंदर वोह ,
सहराओं में भटके यो ,
बेजा तलाशी आसमान में ,
नोच गिराए तारे क्यों ?

<p style="font-size: 16px;">शोले जले , राख हो गए ,
शब् भर जागे , झुलस गए ,
राख मलकर घावो पर ,
झुलसा दिए फिर शोले क्यों ?</p>
<p style="text-align: right;">H.P.RAHI</p>`},
{date:"2012-09-23 17:19:53",name:"ek karaar ki jid pe ( Ghazal )",body:`<p style="text-align: center;font-size:16px">मुझे खौफ है , किसी दिन , मेरी दास्ता तक न होगी ,
निकलेगा चाँद तब भी , एक करार की जिद पे |

<p style="text-align: center;font-size:16px">कब से थी नज़र को , सदियों की बदनसीबी ,
फिर जला आशियाना , मेरे ऐतबार की हद पे |

<p style="text-align: center;font-size:16px">रही खुदाई परेशां , एक सवाल की अरज से ,
मैं गुज़रा कई सहरा , एक जवाब की तलब पे |

<p style="text-align: center;font-size:16px">कातिल का नाम कैसे , निकले मेरी जुबा से ,
ता उम्र बस गया वोह , आवाज़ की लरज़ पे |
<p style="text-align: right;">H.P.RAHI</p>`},
{date:"2013-01-10 18:06:22",name:"mahatva",body:`<p style="font-size:16px">तिनके का सहारा क्या होता है , आज मुझे मालूम हुआ ,
शैतानो की बस्ती में मुझे भा गया तेरा इंसान होना |<span style="font-size:12px">H.P.RAHI</span>`},
{date:"2013-01-16 02:01:28",name:"main kalakar",body:`<p style="font-size: 16px;">उस सूखे कुए में मुझे चाँद टुकडो में पड़ा मिला ,
खुदखुशी करने चला था , किरचे बिखरी पड़ी थी हर ओर ,
कुछ बुझी सी थी , कुछ में अभी थोड़ी रोशनी बाकि थी ,
पूनम का होगा जब कूदा होगा , घट के चौथे का नज़र आता है ,
मैंने अपने कैनवास पर उस रात एक सूरज भी उगाया था ,
जो सुबह होते होते शाम हो गया ,
सिर्फ पीछे छुटे झुलसे कुछ निशान ,
मेरी जमा पूंजी , यह झुलसे उम्रे निशान ,
मैं कलाकार , मेरे साथ बस एक चौथे का चाँद ।</p>
<p style="text-align: right;">H.P.RAHI</p>`},
{date:"2013-03-14 18:09:15",name:"ahsaas ya jajbaat",body:`<p style="font-size:16px">कुछ लिखू दिल करता है,
क्या लिखू? अहसास या जज्बात?
अहसास लिखू तो फूल और खुशबू लिखू ,
और कांटे लिखू तो गुलाबी जज़्बात |<span style="font-size:12px">H.P.RAHI</span>`},
{date:"2013-03-21 11:44:15",name:"ये रंग रंगीली दुनिया",body:`इस रंग रंगीली दुनिया में, हर इन्सां है बदरंग 
फिर भी देख ऐ मन बावरे, हर कोई खेले है सतरंज 
सुना बहुत है, है दुनिया कोई इश्क वालों की, जहाँ है सुकूं 
यहाँ तो बस करते देखा इन्सां को अपनों का ही खूं 
क्या सच है और क्या है भरम, ये कौन है बता पा रहा 
हर जगह देखो इक कठपुतली, इक कठपुतली है नचा रहा।

- DT`},
{date:"2013-03-21 17:18:51",name:"pehchan..",body:`मैं हवा हूँ..या हूँ माटी?

मैं हूँ जीवन..या हूँ काठी?

मैं ख्याल हूँ..या हूँ सपना?

मैं पराया हूँ..या हूँ अपना?

मैं स्पर्श हूँ..या हूँ चुभन?

मैं मुट्ठी हूँ..या हूँ गगन?

मैं हंसी तेरी..या नमकीन जल?

मैं ज़िन्दगी तेरी..या बस इक पल?

तू जानती नहीं मुझे..या में तुझसे बना?

हर पल है मेरा..कुछ अर्थ नया।

&nbsp;

- DT`},
{date:"2013-04-08 19:01:13",name:"Bemaani",body:`<p style="font-size: 16px;">टूटे रोशनदानो, खिडकियों की धुप को ढको जरा
यह पुराने रद्दी अख़बार भी कुछ काम आये
आते जाते नज़र ही पड़ जाये तो
चेहरे को मुस्कान, शायद , आँखों को नमी मिल जाये
कभी खबरों से लाल लाल रहा करते थे
अब उम्र हुयी तो लहू पिला पड़ गया है इनका
अजीब सी बू भी आती है
बूढी अम्मा हमेशा चिल्लाती रहती है
"किसी रद्दी वाले को बेच दो "
कैसे बेच दू ? इनमे से ही किसी अखबार में
बाबूजी का शोक सन्देश छपा था
मुन्ने का नाम भी आया था
साइंस फेयर में डिस्ट्रिक्ट लेवल पर फर्स्ट जो आया था
फुर्सत रहती नहीं के छाटू और फिर काटू वोह सब खबरे
एक क़र्ज़ भी है इन रद्दी कागजों का मुझ पर
मेरी कई नज्मो का बोझ लिया था इन्होने
जाने कितने मानी , बेमानी से बिखरे पड़े होंगे
सोचता हूँ के कभी मिलेगा वक़्त मुझे जरूर
तो छाटूंगा , काटूँगा सब खबरे
समेटून्गा मानी बिखरे हुए सारे
उस दिन किसी पहाड़ पे जाके राकेट बनाकर उडा दूंगा
और जहाज बना कर जमुना में बहा दूंगा
शायद किसी सुलगती खबर को थोड़ी सी नमी मिल जाये
किसी पुरानी उम्मीद को हवा मिल जाये ।</p>
<p style="text-align: right;">H.P.RAHI</p>`},
{date:"2013-04-17 00:16:53",name:"jakhm jab ashko ko pi leta hai ( Ghazal )",body:`<p style="font-size: 16px;">जख्म जब अश्को को पी लेता है ,
जिंदगी को तमाम जी लेता है । 

<p style="font-size: 16px;">वक़्त हो जाता है बदनाम खुद ही ,
बिजलिया जब कलियों पे गिरा देता है । 

<p style="font-size: 16px;">तनहा तन्हाईयो में एक आस फूटी जाती है ,
जब दरख्तों पे कोई नाम मेरा लिखता है । 

<p style="font-size: 16px;">उनसे न कीजियेगा तुम इर्ष्या ,
कभी साकी जहर भी पी जाता है । 

<p style="font-size: 16px;">सुबह होने को पल में गिनता हूँ ,
नींद में कोई आवाज़ दे के जाता है । 

<p style="font-size: 16px;">दिल से मिलता नहीं किसी से मैं ,
इक लुटे घर में ऐसे क्यों कोई आ जाता है । 

<p style="font-size: 16px;">नीलाम कोई क्या होगा किसी के लिए ,
ये "राही" तो हर मंजिल पे बिका जाता है ।
<p style="text-align: right;">H.P.RAHI</p> `},
{date:"2013-04-21 20:41:30",name:"tasavvur ( Ghazal )",body:`<p style="font-size: 16px;">गुमशुदा मिल गया वजुद मेरा ,
सरे राह जो खोया था जानशीं मेरा ।

<p style="font-size: 16px;">तिरे जहां में कुछ तो मिला मुझे ए खुदा ,
वोह तसव्वुर के इरादों का नज़ारा मेरा ।

<p style="font-size: 16px;">बंदिशे इश्क फ़साने को जाने क्या कहिये ,
बंदिशों का करम ही तो है मुकद्दर मेरा ।

<p style="font-size: 16px;">तुमको देखे के शबे आफताब देखे ,
क्या क्या नहीं है आज जमाना मेरा ।

<p style="font-size: 16px;">ख़ुशबुओ की चमन को आहट है ,
सुनते रहिएगा आप आज फ़साना मेरा ।

<p style="font-size: 16px;">रूबरू देखा जो सहरा-ओ-समंदर मैंने ,
याद आ गया वोह मिलन था जो तुम्हारा मेरा । 
<p style="text-align: right;">H.P.RAHI</p>`},
{date:"2013-05-13 16:06:34",name:"furqate-e-yaar ( Shayri )",body:`<p style="font-size: 16px;">नज़्म कुर्बान कर दी , बस एक रात के लिए ,
नींद खा जाती , कागज़ पे जिंदा उतर आती जो ,
नज़्म का क्या है , कल फिर रूबरू हो आएगी ,
फुरकते यार है , नींद कहा रोज आएगी । </p>
<p style="text-align: right;">H.P.RAHI</p>`},
{date:"2013-06-08 14:01:24",name:"rindagi ( Shayri )",body:`<p style="font-size: 16px;">कभी शायद यु भी होता
इस रिंदगी का कुछ सिला होता
मैं मरता मगर मर के ही सही
मेरे नाम पे कोई मयकदा होता |</p>
<p style="text-align: right;">H.P.RAHI</p>`},
{date:"2013-06-08 14:06:59",name:"daur",body:`<p style="font-size: 16px;">दौर वो भी था
दौर ये भी है
कल भी गुज़रा था
आज भी कहा ठहरा है
रात की खामोश निगाहें
बस सुबह तलक जागेंगी
फिराक है फिराक ही सही
गमे यार के चंद रोज़ा
उम्र तलक क्या साथ आयेंगे
ज़िन्दगी रिंदगी से बहोत अलग होती है
तुम भी जानते हो तुमको भी खबर है
बस एक गर्द सी तुमने बिछा रखी है
जब भी साँस आये , या जिंदा हो आओ तुम
तो मुड के देखना पीछे
तुम्हारी बस एक नफ़स से उड़े गर्दे गुबार के सिवा
कुछ नज़र शायद ही आये तुम्हे ।</p>
<p style="text-align: right;">H.P.RAHI</p>`},
{date:"2013-07-06 02:26:05",name:"furqate yaar hai",body:`<p style="font-size: 16px;">फुरक़ते यार है , दिल कैसे भुलाओगे उन्हें
यु खुले जख्म , भला कैसे छुपाओगे उन्हें</p>

<p style="font-size: 16px;">ऐसे बिखरे है फिज़ाओ में जफ़ाओ के समां
जैसे सहराओ में भटकी हुई गुलशन की दुआ
जैसे छिल जाते है जिस्मो में उम्मीदों के निशां
कितने वादों के सिले कैसे निभाओगे उन्हें
फुरक़ते यार है</p>

<p style="font-size: 16px;">टुकडो टुकडो में बटी फिरती है हर रात यहाँ
जैसे काटे नहीं कटती कभी माज़ी की खिज़ा
जैसे होती ही नहीं उम्रे अज़ाबो से रिहा
क्या पुकारेगी सदा क्या ही सुनाओगे उन्हें 
फुरक़ते यार है</p>
<p>फुरक़त - विरह , जफा - अन्याय , सहरा - रेगिस्तान , माज़ी - अतीत , खिजा - पतझड़ , अज़ाब - पीड़ा </p>
<p style="text-align: right;">H.P.RAHI</p>`},
{date:"2013-07-23 20:35:21",name:"Zindagi",body:`<p style="font-size: 16px;">कुछ मायने कुछ आकार समझाती है
जिंदगी जब दर्द में मुस्कुराती है </p>
<p style="text-align: right;">H.P.RAHI</p>`},
{date:"2013-08-10 16:24:20",name:"nira chand",body:`<p style="font-size: 16px;">इतना आसान नहीं लहू रोना 
दिल है जो धड़कने का सबब जानता है 
क़त्ल हो जाता जमानो पहले 
ये निरा चाँद मगर कब मानता है </p>
<p style="text-align: right;">H.P.RAHI</p>`},
{date:"2013-09-16 16:33:01",name:"इक ख्वाब आखो से",body:`इक चुभन सी है आखों में ,
कुछ तो गिरा होगा 
इक कंकर आखो में 
इक ख्वाब आखो से 

- DT`},
{date:"2013-12-10 11:31:03",name:"Tera Sath",body:`Wrote for the dearest friend Vishal Chaudhary on his Engagement -
<p style="font-size: 16px;">वक़्त गुज़रता रहा जैसे
शिद्दत और बढ़ती रही वैसे
एक जूनून हो गया था तुझे पाना
आज जब वोह ख्वाब मुकम्मिल हुआ
तो लगा जैसे जूनून ख़तम नहीं हुआ
शिद्दत अभी पूरी नहीं हुई , और भड़की है
जैसे शोलो को हवा दे दी हो किसी ने
यह मंज़िल नहीं है मेरे लिए
रास्ता अभी और भी है
बस अकेले चलना मुनासिब नहीं था
तेरा साथ मिला तो लगा जैसे
मुश्किलें कम तो नहीं पर आसान हो गयी है ।</p> <p style="text-align: right;">H.P.RAHI</p>`},
{date:"2014-02-06 11:27:34",name:"siyasat ( Ghazal )",body:`<p style="font-size: 16px;">कुछ वबा सी हर ओर महक रही है 
मेरे वतन में आजकल सियासत उबल रही है 

<p style="font-size: 16px;">कई से बा-अदब , कई से बे-अदब 
आबरू ए हर आम उछल रही है 

<p style="font-size: 16px;">कुछ तख़्त नशीनो से कुछ तख्ते आशिक़ों तक 
रोज़ खैरातें तिलिस्म बट रही है 

<p style="font-size: 16px;">कौन जागे , क्यों ही जागे ,  क्या पड़ी है 
जिंदगी को सब तजुर्बा है , सो चल रही है ।</p>
<p>वबा - महामारी , सियासत - राजनीती , बा-अदब - इज्जत के साथ, बे-अदब - बेइज्जती के साथ , तख़्तनशीन - गद्दी पर काबिज </p>
<p style="text-align: right;">H.P.RAHI</p>`},
{date:"2014-05-28 19:43:18",name:"jalte hue khat ( Ghazal )",body:`<p style="font-size: 16px;">दिलजले दिल यु खुदाओं की खुदाई रोए
जलते हुए खत ज्यो असीरी की रिहाई रोए

<p style="font-size: 16px;">दर्द-ए-चारागिरी तो वस्ल-ए-मयख़ाने में थी
और हम बस एक मुलाक़ात की दुहाई रोए

<p style="font-size: 16px;">तजरूबा खूब रहा उल्फत-ए-मुसीबत का
लोग क्यों खाम्ख्वा यार-ए-जुदाई रोये

<p style="font-size: 16px;">जाने कितने हिसाब होने को थे लेकिन
जिंदगी बस वक़्त को ही गवाही रोये |
<p>असीरी - कैद , चारागिरी  - इलाज़ , वस्ल - मिलन</p>
<p style="text-align: right;">H.P.RAHI</p>`},
{date:"2014-06-13 16:14:29",name:"dil karta hai ( Nazm )",body:`<p style="font-size: 16px;">लहू दिल से खरोंच लू 
दिल करता है 
क़त्ल हो जाना मुश्किल है 
कौन कहता है ?
टूट के बिखर जाये कभी 
उस दहलीज़ पे जाकर 
रश्क हो जाये उन्हें मुझपे 
मन होता है |
बेशुमारी शामिल थी 
हर चीज़ में अपनी 
बेहयाई भी होती कुछ 
कितना सहता है ?
<p style="text-align: right;">H.P.RAHI</p>`},
{date:"2014-10-27 14:28:26",name:"karz ( Ghazal )",body:`<p style="font-size: 16px;">मौसमें गुल है अभी इसको गुज़र तो जाने दो
ऐ बहारो मुझको मेरे माज़ी से मिल जाने दो

<p style="font-size: 16px;">आसमानो से परिंदे अलविदा कहते किसे है
जंगलो के राज है सब राज ही रह जाने दो

<p style="font-size: 16px;">तेरे शहर के बाशिंदे कुछ मेरे गाँव में ठहरें हुए है
सितारो बादलो से निकलो ज़रा कुछ रोशनी हो जाने दो

<p style="font-size: 16px;">सहराओं के कर्ज़ कितने थमती थमती साँसो पे है
जागती आखों के सागर कुछ छलक तो जाने दो । <p style="text-align: right;">H.P.RAHI</p>`},
{date:"2015-04-28 12:56:40",name:"tumne kab se sikh liya ( Ghazal )",body:`<p style="font-size: 16px;">तुम तो मेरे थे हमेशा , क्या गज़ब तुमने किया 
लोगो की बातों में आना , तुमने कब से सिख लिया 

<p style="font-size: 16px;">एक करम की थी गुज़ारिश , एक रहम की थी उम्मीद 
आ के तेरे शहर में , क्या गलत हमने किया 

<p style="font-size: 16px;">कितने मयखाने जले और कितनी सदियों के जगे 
क्या रखे इनका हिसाब , जो दिया तुमने दिया 

<p style="font-size: 16px;">शाम के पतजड़ बीने थे मौसमो की याद में 
रात की बारिश ने सब तिनको को फिर तूफां किया । <p style="text-align: right;">H.P.RAHI</p>`},
{date:"2015-06-18 03:08:04",name:"yaad aaya na karo ( Ghazal )",body:`तुम इतना मुझे याद आया न करो
दिल बड़ा कमजोर है, इस तरह बुलाया न करो

साँसों का सफ़र बाकि है ज़रा सा ही और
उन पुराने वास्तो की धूल यु उडाया न करो

क़र्ज़ रातो के अभी बाकि है बहोत सारे
आकर यु ख्वाबो में तुम मुझको जगाया न करो

मेरी गजलो के हर्फ़ है सब हाँफते हुए,
ये बाते तुम युही हँसी में उड़ाया न करो | H.P.RAHI`},
{date:"2015-06-19 17:42:00",name:"dard bhi azeeb cheez hai",body:`<p style="font-size: 16px;">सिर्फ निशान बचे है उंगली पे अंगूठी के ,
दर्द भी अजीब चीज़ है ,
अहसास जुदा है हर बार । H.P.RAHI</p>`},
{date:"2015-07-03 20:03:13",name:"lamhe",body:`Tumse mukhatib hue kab
Tum khas hue kab
Ik lamha tha wo..
wo lamha....wahan.......tab

Lamho ki baatein hain..
Lamho main katt jaati hai..
Lamho ki yaadein hain
Lamho main katt jaani hain

Ye lamho ki bhi kya kahani hai..
aaj ke lamhe, kal khaas hain...
aur jo beet raha hai wo..
uska na koi ehsaas hai

Kaash ke hum ye lamhe yahin rok paate..
kaash ke kucch beete hue lamhe waapis aa jaate.

DT`},
{date:"2016-06-23 11:23:13",name:"raaj ( Ghazal )",body:`आप हमसे यु खफा होते है
महफ़िलो में भी कही राज़ बयां होते है

मेरी नज़्मों में उन आँखो का ज़िक्र रहता है
मयकदो के यु सभी क़र्ज़ अदा होते है

तेरी जानिब कुछ फासले सा बढ़ता हूँ
सूखे हुए फूल जब किताबो से रिहा होते है | H.P.RAHI`},
{date:"2016-09-23 12:14:27",name:"dil dil se ja mila ( Ghazal )",body:`दिल दिल से जा मिला 
जख्मो की फिकर है क्या 

ना ज़माने की बात कर 
तर्क-ए-दिल सुनेगा क्या 

बेतकल्लुफ़ जो है आपसे 
नज़रो की खता है क्या 

हो गयी हर अदा बेहुनर 
चिलमन को उठाया है क्या 

एक वफ़ा एक अहद के सिवा 
कुछ गरज़ दिल करे और क्या | H.P.RAHI`},
{date:"2016-10-25 12:06:45",name:"Salaam Hindustan",body:`On coming back to India from my Nigeria trip - 
तुमसे महोब्बत थी पहले , अब जूनून हो गए हो ,
मेरी सरजमीन ए हिदुस्तान , सलाम । H.P.RAHI`},
{date:"2017-02-28 19:40:23",name:"aag ho jaye ( Ghazal )",body:`ज़माने से कुछ तो सवाब हो जाये 
दीवाने का इश्क़ में मुकाम हो जाये 

कई रोज़ हो गए है तमाशा गुज़रे 
चर्चा-ए-सरे-आम का इन्तेज़ाम हो जाये 

शर्त ये थी के ख़त्म न होंगे 
ज़ुल्म तेरे सभी शराब हो जाये 

तुझको इतनी तो खबर रही होगी 
ये पयामो की आरज़ू थी, आग हो जाये । H.P.RAHI`},
{date:"2017-04-11 16:04:26",name:"ek khayal",body:`<p style="font-size: 16px;">एक ख्याल ,
रोज आता है , दरवाजे खडखडाता है , पीछे देख के मुस्कुराता है , चला जाता है |
मैं बस एक हाथ उठा पाता हूँ ,
वोह उससे मिलने की तकलीफ भी उठाने नहीं देता ,
कहता है , लोगो के बीच हो , खुशनसीब हो ,
अभी रहो यही , मैं मिलूँगा तुम्हे पर अभी नहीं |
<p style="text-align: right;">H.P.RAHI</p>`},
{date:"2017-04-11 18:14:07",name:"badastoor",body:`<p style="font-size: 16px;">जब पिघलता हुआ रोशन सितारा
अपनी आखरी चाल चलता है , खूनी बाज़ी लगाता है
और हार जाता है आसमानी समंदर से हर बार
डूब जाता है

जब शाम ढले साये घर लौटते वक़्त
धुंधले धुंधले निशान आवाज़ों के छोड़ जाते है
रात वो सारे बटोर लेती है
ईंधन जमा करती है

जब रातो के सन्नाटो में
दो जिस्म उबलते है , पिघलते है
एक नयी कहानी बुनते है
सुबह तलक उफ़क पे उकेरते है

हर रोज़ एक नयी परवाज़ लिए
नन्हे परिंदे अपनी शाखों से दूर
क्षितिज की आग़ोश में जाते है
उम्मीद एक और नए दिन की लिए

सहराओ में रेत के दरिया है
जो ठहरे हुए भी बहते से लगते है
जाने किसकी आमद के इंतज़ार में वहा
समय पड़ा हुआ दोपहर काटता है

जब उजाले चीखते है
टुकड़ा टुकड़ा होकर ज़मीन बिखर जाती है
एक कतरा अब्र की तलाश में
निगाहें शाम तलक सिर्फ धोखा खाती है

क्रम जारी है बदस्तूर यही सदियों से
सदियों का ये खेल बहोत निराला है | 
<p style="text-align: right;">H.P.RAHI</p>`},
{date:"2018-01-13 16:30:25",name:"Adhuri Tu Adhura Main",body:`<p style="font-size: 16px;">कलेजा चीर के एक आह उठी
बस एक पल के ईंधन में , सदियों तक जली |
<p style="text-align: right;">H.P.RAHI</p>

Could not resist writing something after listening to the song from Mukkabaaz. Adhuri tu adhura main - https://www.youtube.com/watch?v=SoYkaiZxptY`},
{date:"2018-04-20 13:54:16",name:"Ehtaram",body:`<p style="font-size: 16px;">मुझे अल्लाह कहो या राम कहो 
तुम्हारा मतलब निकले 
तो मुझे इत्मीनान हो 

तुम्हारे सारे गुनाह माफ़ करता हूँ 
मेरा नाम उछालो इस तरह 
की मेरा एहतराम हो 

<p style="text-align: right;">H.P.RAHI</p>

<p style="text-align: center">एहतराम - सम्मान</p>`},
{date:"2019-09-24 12:16:01",name:"Aitmaadi",body:`<p style="font-size: 16px;">जागती रहती है आंखे तेरी आहटे पाकर
यो गुजरते है ज़माने एतमादी होकर | H.P.RAHI</p>`},
{date:"2019-09-24 12:18:50",name:"khamkhwa ( Ghazal )",body:`जब कभी ऐतबार होता है
इश्क़ का इम्तेहां होता है

सिलसिलो की सुनु या दिल की सुनु
रोज़ ही इंतेज़ार होता है

किर्चियां उड़ के चुभ न जाये कही
आईने है, अश्क कौन कहता है

ख़ामख्वा लिख रहे हो, राही
राज़ क्यों कर बयान होता है  H.P.RAHI`},
{date:"2019-10-03 17:01:28",name:"dastak",body:`पुरानी यादें जब किसी दिन युहीं दरवाजा खटखटाती हैं..
न जाने क्या जादू है इनमें, बैठे बैठे कहीं और ले जाती हैं..
किसी पुराने यार, मंज़र या इश्क़ के पास..
कुछ तो मिल जाता है जो बहुत हो ख़ास..
तो बस फिर क्या सोचना है.. ऐसे ही यादें बनाते जाइये..
जी खोल कर आज इस पल को जीते जाइये..
क्या पता, कल दरवाजे पर फिर कोई दस्तक हो|

+ Divye`},
{date:"2020-01-30 12:14:28",name:"gunahgaar",body:`<p style="font-size: 16px;">ये शरजील क्यों है ?
क्यों है ये रामभक्त गोपाल?

ये कुछ यु है के कुछ लोग है 
जिन्हे चाहिए सम्पूर्ण नियंत्रण 
जो चाहते है बेपनाह ताकत 
ये कौन है ? ये कब से है ?

ये तब से है के जब से तारीख़ खुद है 
और ये है इसी तारीख़ के
सब से बड़े गुनाहगार | H.P.RAHI
<p style="text-align: right;">H.P.RAHI</p>`},
{date:"2020-02-25 11:14:08",name:"aag",body:`<p style="font-size: 16px;">जलाओ तुम भी कुछ इबारतें
हम भी कुछ पन्ने जला ही देते है इस बेतुकी किताब के
जिसे लिख के गए थे कुछ मतवाले लोग
देश चलाने का ताना बुना था
जुर्रत की थी हमें समझाने की के कौन राह चलनी है
आओ बता दे खुल के
हमें मंज़ूर नहीं ये जो तुम्हारी जुर्रत थी
नफरतों के बीज
कोई आज ही तो किसी ने बोए नहीं है
इस कृषि प्रधान देश को
ये फसल तो अभी कई सदियों तक काटनी है
तुम क्या फंदे से झूल जाते हो सिर्फ भूख से मजबूर होकर
तुम हमसे सीखो के नफरत की आग से पेट कैसे भरता है
नींद भी गजब की आती है
<p style="text-align: right;">H.P.RAHI</p>`},
{date:"2021-01-28 13:06:08",name:"migration",body:`ये जो मेरे पांव के छाले है,
ये दरअसल कुछ और है,
ये तुम्हारी सफेद वर्दी पे सजे हुए सितारे हैं।

जब मैं इन शानदार सड़कों पे गुजरता,
मील  के पत्थरो को ताकता हूं,
कदम दर कदम अपने घर से खुद को थोड़ा और दूर पाता हू,
तुमने गति सीमा के खूब नए कीर्तिमान गढ़े है।

बहोत कम ही बोझ ले के निकला था,
बस कुछ एक जिस्म है मेरे साथ,
और थोड़ा बहुत भूख का सामान,
ये जो तुम्हारी राहतो की नुमाइशे है ना,
मैं इनसे इत्तेफाक रखता हू,
पर अभी सोचने का समय थोड़ा कम है,
अगर, पहोच गया, तो परखुंगा।

H.P.RAHI`},
{date:"2022-04-20 15:46:14",name:"bulldozer",body:`

साथ और विकास की ये अजब रस्म निभाते हो
आधे-कच्चे अरमानो पे ये पक्के बुलडोज़र चलाते हो

थोड़ा ही वक़्त गुजरा था की थोड़ा होश ही आता
बुझने को थी नफरते कि फिर वही हवा चलाते हो

सदियों से समझ पे पड़ी धूल थोड़ी हटाते तो
धर्म कर्म के ये कैसे मतलब समझाते हो

तुम्हारी नियत का पता तो था पर दिल मानता नहीं था
हर बात फरेब थी, तुम क़यामत के जाल बिछाते हो|

H.P.RAHI
`},
{date:"2022-04-21 00:12:34",name:"davanal",body:`

जहा सिर्फ नफ़रत की फसल कटती है
और दावानल की चिंगारी सिचि जाती है
वो जमींन कौनसी है ?

जहा ढलती है तलवारे उस पवित्र धातु से
जिसमे संभावना थी हल और हंसिया बनने की
वो ईमारत कौनसी है ?

दम्भ और रूढ़ि परम्पराओ, विचारो के शोर में
जहा दिल टूटने की तो आवाज़ तक सुनाई नहीं देती
वो समाज कौनसा है ?

ग़ुरबत जहा सिर्फ एक प्रयोगशाला है
धर्म, धर्म नहीं, जैसे कोई अफीम है
वो चमन कौनसा है ?

उस चमन से मेरा कोई नाता नहीं
उस समाज का मैं बाशिंदा नहीं
उस इमारत की पनाह मुझे मंजूर नहीं
उस जमींन से मेरी जड़े मैंने अब उखाड़ ली

अब असमंजस में हु
कहा जाऊ ?

H.P.RAHI
`},
{date:"2022-05-07 08:38:25",name:"Voyager 1",body:`

रोशनी का एक बिंदु मात्र है
“वॉयेजर एक” ने फ़ोटो खींच के बताया था
सौर मंडल के आखरी छोर से
“धरती” मुझे तो बहोत बड़ी लगती थी
बिल्कुल वैसे ही जैसे हमने अहंकार पाला है

हिटलर, अमीन, नीरो, माओ, चंगेज़,
ईसा, बुद्धा, कृष्णा, नानक, पैगम्बर
अपोकलिप्स, एक्सओडस, होलोकॉस्ट
बहार, वादी, कविता, संगीत
पावर, पॉलिटिक्स, पॉल्युशन, करप्शन
राइट, लेफ्ट, धर्म, कर्म
जोबन, बचपन, सुहाग शैय्या, मृत्यु शैय्या
रिश्ते, दोस्त, जज्बात, मुहब्बत, 
मुफ़लिस, समृद्ध, गृहस्थी, फकीरी
निराशा, उम्मीद, महत्वाकांक्षा
तुम, मैं, तेरा, मेरा
सिर्फ मैं, सिर्फ मेरा

सब बेमानी लगने लगा
बड़े अचरज से निहारा वो फ़ोटो जब मैंने

बड़े अचरज से निहारा वो फ़ोटो जब मैंने
टकटकी लगाए
एक विचार कौंधा मुझे
धरती सिर्फ अहंकार तो नही
ये बेमतलब बेमानी नही
ये रोशनी का एक बिंदु मात्र सही
ये ही रोशनी है
और मैँ ही ब्रह्मा हूं
“मैं” नही

H.P.RAHI
`},
{date:"2022-05-24 11:51:40",name:"Sanshodhan",body:`एक महान राष्ट्र के
एक विशाल प्रान्त के
एक छोटे से जिले का
एक साधारण सा गांव

वहा का एक भ्रष्ट सरपंच जमींदार
और एक आधी कच्ची ईमारत
जिसे धूर्तता से, बल से
गोदाम बना हड़पना चाहता है
जिसे बनना तो था एक स्कूल

उस स्कूल के लिए लड़ी
उस गांव की एक साधारण औरत
जैसे गिद्धों, चीलों से जा भिड़ी हो
एक छोटी सी चिड़िया

उसका संघर्ष किसी क्रांति से
उसका झुंझारूपन किसी सत्याग्रह से
उसका संकट किसी धर्म युद्द से
कमतर जरा भी नहीं

एक महान राष्ट्र के
एक विशाल प्रान्त के
एक छोटे से जिले का
एक साधारण सा गांव

H.P.RAHI

Written after watching “Sanshodhan”. A film by Govind Nihalani.`},
{date:"2023-01-18 07:00:00",name:"Upchar",body:`रात ढली नही , सुबह हुई नही
सूरज अभी अलसभोर में है
पर मन का सूरज अपनी दोपहरी पे है 
कई दसियों विचार भिड़ रहे है 
लगता है ज्यो मन को कोई रोग लगा हो
जो सोने नही देता
आज पहली बार बिस्तर से उठकर
अपनी डेस्क तक पहोच पाया
सब डायरी में नोट कर डाला
दसियों विचार
अब नींद तो नही है
पर मन शांत है
ये एक प्रयोग सा लगता है
शायद उपचार भी हो ।

H.P.RAHI`},
{date:"2020-02-03 21:25:00",name:"raaz",body:`तूने आँखों की किताबों से पढ़ लिया होगा 
राज़ जो मैंने छुपा रखा था 

जब कभी आग बुझने पायी थी 
तेरी आहट ने फिर जगाया था 
सर्द साँसो से एक चुभन गुज़री ऐसी 
काटने रात घर जलाया था 

यु उदासी में बहार गुज़री थी 
बज़्म-ए-रिन्दा को जाम खाली था 
दरया-ए-दिल से रोशनाई लेकर मैंने 
नाम लिख लिख के यु पुकारा था  

H.P.RAHI`},
]

